import React from "react";
import { Alert, Button, Form, Input } from "antd";
import { registration, setTokenInLocalStorage } from "../../lib/api";
import { loginUserAc } from "../../redux/actions/opening/action";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import throwError from "../../service/throwError";
import TB_ORANGE_LOGO from "../../images/TB full orange.png"
type SignUpFormProps = {
  loginUserAc(token: string, data: any): void;
} & RouteComponentProps;

class InvitedSignupIndex extends React.Component<SignUpFormProps  & RouteComponentProps> {
  state = {
    validMobile: true,
    loading: false,
    errorMessage: "", // Add a state to store the error message
    invitedEmailAddress:"",
    isEmailParamsExist:false

  };

  validatePassword = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a password!"));
    }
    if (value.length < 8) {
      return Promise.reject(
        new Error("Password must be at least 8 characters")
      );
    }
    if (/\s/.test(value)) {
      return Promise.reject(new Error("Password cannot contain spaces"));
    }
    return Promise.resolve();
  };
  onFinish = (values: any) => {
    this.setState({ loading: true });
    const urlParams = new URLSearchParams(window.location.search);
    const invitationSecret:any= urlParams.get('invitation_secret');
    registration({
      user: values,
    },invitationSecret).then((res) => {
      setTokenInLocalStorage("login_token", res.token).then(async(flag: any) => {
        if (flag) {
          this.props.loginUserAc(res.token, res.user);

          if (!res.user.verified) {
            return this.props.history.push("/onboarding");
          }
          return this.props.history.push("/o");
        }
        this.setState({ loading: false });
      });
    }).catch((error) => {
      if (error.response && error.response.status === 409) {
        this.setState({
          errorMessage: "An account already exist with this email address",
        })
      } else
        throwError(error)

      this.setState({ loading: false });
    });
  };
  componentDidMount(): void {
    let emailValue: any = {};
    emailValue = this.props.match.params;
    this.setState({invitedEmailAddress:emailValue.email})
    this.setState({isEmailParamsExist:true})
    
  }

  render() {
    return (
      <div className="w-full lg:w-1/2 flex lg:items-center lg:justify-center p-8 ">
        {this.state.isEmailParamsExist && (
          <div className="w-full sm:w-full md:w-full lg:w-10/12 xl:9/12 2xl:w-8/12 space-y-8">
            <div className="lg:hidden flex  mb-8">
              <img
                src={TB_ORANGE_LOGO}
                alt="Tacitbase Logo"
                className="w-48"
                style={{ backgroundColor: "transparent" }}
              />
            </div>
            <div className="text-left space-y-3">
              <h2 className="lg:text-3xl text-xl  font-bold bg-gradient-to-r text-tacitbase-secondary3">
              Create your account
              </h2>
              {this.state.errorMessage && (
                <Alert message={this.state.errorMessage} type="error" />
                )}
            </div>
            <Form
              name="normal_login"
              className="login-form text-xs w-full"
              initialValues={{
                remember: true,
                email: this.state.invitedEmailAddress,
              }}
              onFinish={this.onFinish}
              layout="vertical"
            >
              <Form.Item
                name="email"
                label="Work Email"
                rules={[
                  { required: true, message: "Please input your Work Email!" },
                  { type: "email", message: "Invalid email format" },
                ]}
              >
                <Input
                  value={this.state.invitedEmailAddress}
                  disabled={true}
                  size="large"
                  defaultValue={this.state.invitedEmailAddress}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, validator: this.validatePassword }]}
              >
                <Input.Password size="large" />
              </Form.Item>
              <Form.Item style={{ marginTop: "20px" }}>
                <Button
                  loading={this.state.loading} // Use the loading prop
                  htmlType="submit"
                  type={"primary"}
                  className="w-full mb-3"
                  size="large"
                >
                  Sign up
                </Button>
              </Form.Item>
              <p className="text-gray-600 text-xs md:text-sm">
              By signing up, you agree to our&nbsp;
              <a
                href="https://www.tacitbase.com/terms-of-use"
                style={{ color: "#009D79" }}
              >
                terms of use{" "}
              </a>
              &nbsp;and&nbsp;
              <a
                href="https://www.tacitbase.com/privacy"
                style={{ color: "#009D79" }}
              >
                {" "}
                privacy policy.
              </a>
              <p>
                Need help? Visit&nbsp;
                <a
                  href="https://support.tacitbase.com/"
                  style={{ color: "#009D79" }}
                >
                  support.
                </a>
              </p>
            </p>
            </Form>
            </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginUserAc: (token: string, data: any) => dispatch(loginUserAc(token, data)),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(InvitedSignupIndex));
