// ResetPassword.js

import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { BACK_TO_LOGIN, RESET_PASSWORD_HEADING } from '../../constant'
import Link from 'antd/es/typography/Link'
import { forgetPassword } from '../../lib/api'
import CheckEmail from './CheckResetPwdMail'
import { useHistory, useLocation } from 'react-router-dom';
import { tokenVerify } from '../../service/tokenVerify'
import BrandingPanel from '../../components/signUp/BrandingPanel'
const {Text} = Typography;

const ResetPassword = () => {
  // const history = useHistory()
  const location: any = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  useEffect(()=>{
    // If the login token is valid and the user is already logged in, and they attempt to access /password/change, the user will be automatically redirected to /o.
        if (tokenVerify()) {
            history.push(`/o`);
        }
      },[])

  useEffect(() => {
    // Check if the success property is present in the location state
    if (location.state && location.state.success !== undefined) {
      setSuccess(location.state.success)
    }
  }, [location.state])

  const onFinish = (values: any) => {
    setLoading(true);
    const forgotPwdObject = {
            sendForgotPasswordEmail: true,
      loginId: values.email.toLowerCase(),
    };

    forgetPassword(forgotPwdObject)
      .then((res) => {
        if (res) {
          setSuccess(true)
          setEmailValue(values.email)
        }
            })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false)
      })
  };

    return (
      <div className="flex min-h-screen max-h-screen overflow-hidden ">
      {/* Left Panel */}
      <BrandingPanel />

      {/* Right Panel - Sign Up Form */}
      <div className="w-full lg:w-1/2 flex lg:items-center lg:justify-center p-8 ">
      <div className="w-full sm:w-full md:w-full lg:w-10/12 xl:9/12 2xl:w-8/12 space-y-8">

          {!success ? (
                  <>
                    <Text className="lg:text-2xl text-xl  font-bold bg-gradient-to-r text-tacitbase-secondary3">
                      {capitalizeFirstLetter('reset password')}
                    </Text>
                    <div className="text-gray-600">{RESET_PASSWORD_HEADING}</div>
                    <Form
                      name="resetPassword"
                      onFinish={onFinish}
                      initialValues={{ email: emailValue }}
                      layout="vertical"
                    >
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          { required: true, message: 'Please input your email!' },
                          { type: 'email', message: 'Invalid email format' },
                        ]}
                      >
                        <Input size="large" type="email" />
                      </Form.Item>

                      <Button
                        loading={loading}
                        htmlType="submit"
                        className="w-full my-3"
                        type='primary'
                        size="large"
                      >
                        Send instructions
                      </Button>
                      <Link href={`/login`}>{BACK_TO_LOGIN}</Link>
                    </Form>
                  </>
                ) : (
                  <CheckEmail email={emailValue} success={success} />
                )}
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
