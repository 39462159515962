
export function init_feedback(state: any, action: any) {
  return {
    ...state,
    feedback: {
      [action.meetingId]:action.data
    },
  };
}

export function update_feedback(state: any, action: any) {
  const meetingId = action.data.meeting_id;
  const feedbackId = action.data.id;

  return {
    ...state,
    feedback: {
      ...state.feedback, // Preserve other feedback entries
      [meetingId]: (state.feedback[meetingId] || []).map((feedback: any) =>
        feedback.id === feedbackId
          ? { ...feedback, feedback_description: action.data.feedback_description ,feedback_option : action.data.feedback_option} // Update description
          : feedback // Keep other feedback items unchanged
      ),
    },
  };
}


export function save_feedback(state: any, action: any) {
  const meetingId = action.data.meeting_id;

  return {
    ...state,
    feedback: {
      ...state.feedback, // Preserve the existing feedback object
      [meetingId]: [
        ...(state.feedback[meetingId] || []), // Preserve existing comments array or use an empty array
        action.data, // Add the new comment
      ],
    },
  };
}

export function getActiveMeetings(state:any,action:any){
  return {
   ...state,
    totalMeetings: action.payload,
  }
}

