import React from 'react';
import { Modal, Card, Typography, Row, Col } from 'antd';
import {
  RobotOutlined,
  FileSearchOutlined,
  PercentageOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';

const { Title, Text } = Typography;

interface OnboardingModalProps {
  visible: boolean;
  onClose: () => void;
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({ onClose,visible }) => {
  const features = [
    {
      icon: <RobotOutlined className="text-4xl text-blue-500" />,
      title: "AI Job Post Generation",
      description: "Create compelling job descriptions automatically with AI assistance",
    },
    {
      icon: <FileSearchOutlined className="text-4xl text-purple-500" />,
      title: "Smart Resume Parsing",
      description: "Extract key information from resumes automatically with AI",
    },
    {
      icon: <PercentageOutlined className="text-4xl text-green-500" />,
      title: "Resume Relevance Scoring",
      description: "AI-powered matching between job requirements and candidates",
    },
    {
      icon: <ScheduleOutlined className="text-4xl text-orange-500" />,
      title: "Interview Scheduling,Summary & Feedback",
      description: "Efficiently schedule interviews, collect summaries, and provide feedback seamlessly.",
    },
  ];
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
      className="rounded-xl overflow-hidden"
      closable={true}
      centered
    >
      <div className="p-4">
        <div className="text-center">
          <div className="inline-block px-4 py-1 rounded-full bg-blue-100 text-blue-600 text-sm font-medium mb-4">
            14-DAY GROWTH TRIAL PLAN
          </div>
          <Title level={4} className="!mb-2">
            Experience AI-Powered Recruitment!
          </Title>
          <Text className="text-gray-600 block mb-4">
            Transform your hiring process with our cutting-edge AI features
          </Text>
          <Text className="text-gray-500 text-sm block my-4">
            14 days of unlimited access to all Growth plan features
          </Text>
        </div>
        <Row gutter={[16, 16]}>
          {features.map((feature, index) => (
            <Col xs={24} md={12} key={index}>
              <Card 
                className="h-full hover:shadow-lg transition-all duration-300"
                bordered={false}
                style={{ background: '#f8fafc' }}
              >
                <div className="flex flex-col h-full">
                  <div className="flex items-center space-x-4 mb-4">
                    <div className="flex-shrink-0">
                      {feature.icon}
                    </div>
                    <Title level={5} className="!mb-0">
                      {feature.title}
                    </Title>
                  </div>
                  <Text className="text-gray-600 mb-4">
                    {feature.description}
                  </Text>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Modal>
  );
};

export default OnboardingModal;