import React, { useEffect, useState } from "react";
import VideoTranscript from "./VideoTranscript";
import { LeftOutlined } from "@ant-design/icons";
import TacitBaseModal from "../../ant/TacitbaseModal";
import { Overlay } from "../hook/Overlay";
import { Button, Tabs, Tooltip, Typography } from "antd";
import MeetingVideo from "./MeetingVideo";
import Summery from "./Summery";
import { getSingleMeeting } from "../../lib/api";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import MeetingParticipants from "./MeetingParticipants";
import Feedback from "./Feedback";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
const { Text } = Typography;

interface VideoSummeryModalProps {
  applicationId: string;
}

const VideoSummeryModal = (props: VideoSummeryModalProps) => {
  const [data, setData] = useState<any>([]);

  const history = useHistory();
  const params: { board_id: string; card_id: string; meeting_id: string } =
    useParams();

  useEffect(() => {
    const fetchMeeting = async () => {
      try {
        const response = await getSingleMeeting(params.meeting_id);
        setData(response); // Assuming the response has a transcript field
      } catch (error) {
        console.error("Error fetching meeting:", error);
      }
    };

    fetchMeeting();
  }, []);

  const backToMeeting = () => {
    if (params.board_id && params.card_id) {
      history.push({
        pathname: `/o/${props.applicationId}/b/${params.board_id}/tracker/${params.card_id}`,
      });
    } else {
      history.push({
        pathname: `/o/${props.applicationId}/meetings`,
      });
    }
  };

  return (
    <TacitBaseModal
      keyboard={false}
      maskClosable={false}
      visibility={true}
      width={"100%"}
      title={
        <>
          <Overlay onClose={() => {}} scope={""}>
            <div
              className={`w-full flex justify-between items-center py-3`}
              style={{ height: 52 }}
            >
              <div className="flex">
                <Button
                  style={{
                    color: "#000000",
                    transition: "color 0.3s",
                  }}
                  icon={<LeftOutlined />}
                  type="link"
                  name="Back to pool"
                  onClick={backToMeeting}
                >
                  Back to Card
                </Button>
                <div className="flex items-center gap-1 font-normal">
                  <MeetingParticipants
                    meetingParticipants={data?.meeting_participants || []}
                  />
                </div>
              </div>
              <div className="flex flex-row pr-4 font-normal">
                <Text>{renderCreatedAt(data?.join_at)}</Text>
              </div>
            </div>
          </Overlay>
        </>
      }
      closeIcon={null}
      content={
        <>
          <div
            style={{
              margin: 0,
              overflow: "hidden",
              width: "calc(100vw - 150px )",
              maxWidth: "calc(100vw - 150px )",
              height: "calc(100vh - 161px )",
              maxHeight: "calc(100vh - 100px )",
              borderRadius: "4px",
            }}
          >
            <div style={{ display: "flex" }} className="h-full  ">
              {/* First Part */}
              <div
                style={{ width: "60%" }}
                className="flex flex-col h-full flex-shrink-0 border-l border-b border-t border-r"
              >
                <h3 className="text-lg font-semibold px-4 ">
                  {data?.meeting_metadata?.title
                    ? capitalizeFirstLetter(data?.meeting_metadata?.title)
                    : "Video summery"}
                </h3>
                <Tabs
                  defaultActiveKey={
                    !data?.tb_video_url || !data?.transcript
                      ? "feedback"
                      : "summary"
                  }
                  className="px-4 -mt-3"
                  key={data.id}
                >
                  <Tabs.TabPane
                    tab={
                      <Tooltip
                        title={
                          !data?.tb_video_url && "Summary is not available"
                        }
                      >
                        <span>Summary</span>
                      </Tooltip>
                    }
                    key="summary"
                    disabled={
                      !data?.tb_video_url ||
                      !data.transcript ||
                      data.transcript === null
                    }
                  >
                    {/* Content for Summary tab */}
                    <div
                      className="w-full h-full overflow-y-scroll flex"
                      style={{
                        height: "calc(100vh - 280px)",
                      }}
                    >
                      {/* You can add your summary content here */}
                      <Summery />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <Tooltip
                        title={
                          !data?.transcript && "Transcript is not available"
                        }
                      >
                        <span>Transcript</span>
                      </Tooltip>
                    }
                    key="transcript"
                    disabled={
                      !data?.tb_video_url ||
                      !data.transcript ||
                      data.transcript === null
                    }
                    // className="overflow-y-scroll"
                  >
                    <div
                      className="w-full h-full overflow-y-scroll flex"
                      style={{
                        height: "calc(100vh - 280px)",
                      }}
                    >
                      <VideoTranscript transcript={data.transcript} />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Feedback" key="feedback">
                    <div
                      className="w-full h-full overflow-y-scroll flex"
                      style={{
                        height: "calc(100vh - 280px)",
                      }}
                    >
                      {/* Add your feedback component or content here */}
                      <Feedback />
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
              {/* Second Part */}
              <div
                style={{
                  width: "40%",
                }}
                className="flex flex-col h-full border-t border-r"
              >
                <MeetingVideo videoUrl={data.tb_video_url} />
              </div>{" "}
            </div>
          </div>
        </>
      }
      footer={<div className="m-0"></div>}
      style={{
        overflowY: "hidden",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: "50px",
        left: "10px",
        bottom: "10px",
        right: "0px",
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        padding: "0px",
        borderRadius: "4px",
      }}
      className="review-modal-content"
    />
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
});
export default connect(mapStateToProps)(VideoSummeryModal);
