// import jwtDecode from "jwt-decode";
import React, { Component } from "react";
import { connect } from "react-redux";

import { Dispatch } from "redux";
import Ats from "./Ats";
import {
  getAllSources,
  getAvailableIntegrations,
  getBillingActivePlans,
  getDomain,
  getEsignIntegration,
  getOrgDetails,
  getSubscriptionDetails,
} from "./lib/api"
import {
  add_current_plan,
  addDomainOfOrg,
  addWsRequest,
  clearTabStates,
  current_org,
  getActiveBoard,
  getActiveMeetings,
  initJobPlatform,
  initMembership,
  initSources,
  updateEsignDetails,
} from "./redux/actions/opening/action";
import { tokenVerify } from "./service/tokenVerify";
// import {  Plan, Product, ThirdPartyIntegrations, loginUser } from "./type/type"
import { Domain, Plan, Product, ThirdPartyIntegrations, eSignDetails, loginUser } from "./type/type"
import WSComponent from "./WSComponent";
import { isMainProductURL } from "./utils/regExMatcher";
import { orgConfigurationMiddleware } from "./service/orgConfigurationMiddleware";
import { Space, Spin, notification } from "antd";
import LoadingScreen from "./components/PageLoader";
import Tacitbase1 from "./images/TB full orange.png";
import { Source } from "./lib/apiRes";
import { logoutFromAllTabs } from "./utils/logoutFromAllTabs";
type AppProps = {
  clearTabStates(): void;
  add_current_plan:(product:Product[])=>void;
  currentOrg(orgData: any): void;
  addWsRequest(actionRequest: any): void;
  initMembers(members: any): void;
  match?: any;
  loginUser: loginUser;
  clientId: string;
  initSources(sources:Source[]):void;
  initJobPlatform(Integrations: ThirdPartyIntegrations[]):void;
  getActiveBoard(data: any):void;
  getActiveMeetings(data: any):void;
  addDomainOfOrg(domainRes: Domain): void;
  updateEsignDetails(data: eSignDetails): void;
  eSignDetails: eSignDetails;
};
class App extends Component<AppProps> {
  state = {
    success: false,
    loading: false,
  };
  componentWillUnmount() {
    window.removeEventListener("offline", this.handleOffline);
    window.removeEventListener("online", this.handleOnline);
  }
  handleOffline = () => {
    notification.destroy();
    setTimeout(() => {
      notification.open({
        type: "error",
        message: <div className=" text-sm font-medium">You are offline.</div>,
        description: "Please check your internet connection",
        duration: 0,
        placement: "bottomLeft",
      });
    }, 500);
  };

  handleOnline = () => {
    notification.destroy();
    setTimeout(() => {
      notification.open({
        type: "success",
        message: (
          <div className=" text-sm font-medium ">Network is back online.</div>
        ),
        description: "",
        duration: 4,
        placement: "bottomLeft",
      });
    }, 500);
  };
  handleMessage = (event: StorageEvent) => {
    if (event.key === 'logout') {
      logoutFromAllTabs()
    }
  };
  componentDidMount = () => {
    window.addEventListener('storage', this.handleMessage);
    window.addEventListener("offline", this.handleOffline);
    window.addEventListener("online", this.handleOnline);
    if (tokenVerify()) {
      // If we are going to implement org switcher, when user is inside Organization then we need to think about below 2 lines... because if user switches from org1 to org2 its org_name and org_id doesn't change in UI
      // this.props.clearTabStates();
      // sessionStorage.removeItem("current_tab_application_token");
      let params = window.location.pathname.split("/");
      if (isMainProductURL(location.pathname)) {
        this.props.clearTabStates();
        getBillingActivePlans("tacitbase").then((res) => {
          const unique: Product[] = [];
          if (res.plans && Array.isArray(res.plans)) {
              res.plans.forEach((plan: Plan) => {
                  if (!unique.some((item) => item.id=== plan.product.id)) {
                      unique.push(plan.product);
                  }
              });
              this.props.add_current_plan(unique);
          } 
      }).catch((err) => {
          console.log("Error fetching billing plans:", err);
      });
        this.setState({ loading: true });
        getOrgDetails(params[2])
          .then((appRes) => {
            this.props.currentOrg(appRes);
            orgConfigurationMiddleware(appRes, this.props.loginUser)
              .then((flag) => {
                if (flag){
                  this.setState({
                    success: true,
                  });
                  getSubscriptionDetails().then((res)=>{
                    this.props.getActiveBoard(res?.subscriptions?.job_board)
                    this.props.getActiveMeetings(res?.subscriptions?.meetings_count)
                  }).catch((err)=>{
                    console.log("Error getting subscription details",err);
                  })
                    getEsignIntegration().then((res:any)=>{
                      if(res?.id){
                        this.props.updateEsignDetails({ connected: true ,tools:[res?.client]});
                      }
                    }).catch((err)=>{
                      console.log("Error getting esign details",err);
                    })
                  getAllSources().then((source)=>{  
                    this.props.initSources(source);
                  })
                  getAvailableIntegrations().then((integ)=>{
                    this.props.initJobPlatform(integ)
                  })
                  getDomain().then((res) => {
                    this.props.addDomainOfOrg(res);
                  });
                }

              })
              .finally(() => {
                // Set loading to false after API call is complete
                this.setState({ loading: false });
              });
          })
          .catch(() => {
            // Set loading to false if API call fails
            this.setState({ loading: false });
          });
      } else {
        this.setState({
          success: true,
        });
      }
    } else {
      this.setState({
        success: true,
      });
    }
  };
  render() {
    return (
      <>
        {this.state.loading && (
          <LoadingScreen
            content={<></>}
            message=""
            footer={
              <div className="flex justify-center">
                <Space>
                  <img
                    src={Tacitbase1}
                    color="#8E8CE0"
                    alt=""
                    width="200px"
                    height="200px"
                  />
                  {/* <Title level={3} style={{ margin: 0, color: "#6563C6" }}>{"Tacitbase"}</Title> */}
                </Space>
              </div>
            }
            loaderIcon={
              <div className="flex flex-col space-y-6">
                {" "}
                <Spin  tip="" size="large" >
                  <div className="content" />
                </Spin>
              </div>
            }
          />
        )}
        {this.state.success ? (
          <React.Fragment>
            <WSComponent></WSComponent>
            <Ats />
          </React.Fragment>
        ) : null}
      </>
    );
  }
}

const mapPropsToState = (dispatch: Dispatch) => ({
  clearTabStates: () => dispatch(clearTabStates()),
  initMembers: (members: any) => dispatch(initMembership(members)),
  addWsRequest: (actionRequest: any) => dispatch(addWsRequest(actionRequest)),
  initSources: (sources: Source[]) => dispatch(initSources(sources)),
  initJobPlatform: (Integrations: ThirdPartyIntegrations[]) => dispatch(initJobPlatform(Integrations)),
  currentOrg: (orgData: any) => dispatch(current_org(orgData)),
  add_current_plan:(data:Product[]) => dispatch(add_current_plan(data)),
  getActiveBoard:(data: any)=> dispatch(getActiveBoard(data)),
  getActiveMeetings:(data: any)=> dispatch(getActiveMeetings(data)),
  addDomainOfOrg: (domainRes: Domain) => dispatch(addDomainOfOrg(domainRes)),
  updateEsignDetails: (data: eSignDetails) => dispatch(updateEsignDetails(data)),

});
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  clientId: state.opening.clientId,
  eSignDetails: state.opening.eSignDetails
});
export default connect(mapStateToProps, mapPropsToState)(App);
