import React, { useState, useEffect } from "react";
import { getPromptsTemplates, getAllInsights } from "../../lib/api"; // Adjust the import path as necessary
import { Select, Typography, List, Spin, Empty } from "antd";
import { useParams } from "react-router-dom";
const { Title, Paragraph } = Typography;

const MeetingSummary = () => {
  const [selectedPrompt, setSelectedPrompt] = useState<string | undefined>(
    undefined
  );
  const [prompts, setPrompts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [insights, setInsights] = useState<any>(null);
  const [loadingInsights, setLoadingInsights] = useState<boolean>(false);
  const params: { meeting_id: string } = useParams();

  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const response = await getPromptsTemplates();
        setPrompts(response);
        setSelectedPrompt(response[0]?.id);
        const response1: any = await getAllInsights(
          response[0]?.id,
          params.meeting_id
        );
        setInsights(response1);
      } catch (error) {
        console.error("Error fetching prompts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrompts();
  }, []);

  const handleChange = async (value: string) => {
    setSelectedPrompt(value);
    setLoadingInsights(true);
    try {
      const response: any = await getAllInsights(value, params.meeting_id);
      setInsights(response);
    } catch (error) {
      console.error("Error fetching insights:", error);
    } finally {
      setLoadingInsights(false);
    }
  };

  const renderNotes = (notes: any) => {
    return Object.entries(notes)?.map(([topic, content]: any) => (
      <div key={topic} className="mb-2">
        <Typography.Text strong>{topic}:</Typography.Text>
        <ul className="pl-4 my-1">
          {Object.entries(content)?.map(([subtopic, details]: any) => {
            if (typeof details === "object" && !Array.isArray(details)) {
              return (
                <li key={subtopic}>
                  <Typography.Text>{subtopic}:</Typography.Text>
                  <ul className="pl-8">
                    {Object.entries(details)?.map(([key, value]) => (
                      <li key={key}>
                        {typeof value === "string" ? value : `${key}: ${value}`}
                      </li>
                    ))}
                  </ul>
                </li>
              );
            } else {
              return (
                <li key={subtopic}>
                  <Typography.Text>{subtopic}:</Typography.Text>
                  <ol className="pl-8 my-1">
                    {Array.isArray(details) ? (
                      details.map((detail: string, index: number) => (
                        <li key={index}>{detail}</li>
                      ))
                    ) : (
                      <li>{details}</li>
                    )}
                  </ol>
                </li>
              );
            }
          })}
        </ul>
      </div>
    ));
  };

  return (
    <div className="flex w-full flex-col">
      <Title level={5}>Meeting Summary</Title>
      {loading ? (
        <Paragraph>Loading prompts...</Paragraph>
      ) : (
        <Select
          value={selectedPrompt}
          style={{ width: 200 }}
          onChange={handleChange}
        >
          {prompts.map((prompt) => (
            <Select.Option key={prompt.id} value={prompt.id}>
              {prompt.name}
            </Select.Option>
          ))}
        </Select>
      )}
      {loadingInsights ? (
        <div className="flex justify-center items-center h-full w-full">
          <Spin tip="Loading..." />
        </div>
      ) : insights == null || insights.notes == null ? (
        <div className="flex justify-center items-center h-full w-full">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No summery available</span>}
          />{" "}
        </div>
      ) : (
        <List
          header={null}
          dataSource={[insights]}
          className="flex overflow-y-scroll"
          renderItem={(item) => (
            <List.Item>
              <div>{renderNotes(item?.notes)}</div>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default MeetingSummary;
