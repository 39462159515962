import React from "react";

interface MeetingVideoProps {
  videoUrl: string;
}

const MeetingVideo: React.FC<MeetingVideoProps> = ({ videoUrl }) => {
  const isValidVideo = Boolean(videoUrl);

  return (
    <div className="flex justify-center items-center max-w-full max-h-full p-4">
      {isValidVideo ? (
        <video className="w-full max-w-[670px] object-contain" controls>
          <source key={videoUrl} src={videoUrl} type="video/mp4" />
          <track kind="captions" srcLang="en" label="English" default />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div
          className="relative w-full max-w-[670px] bg-black flex items-center justify-center"
          style={{ height: "350px" }}
        >
          <div className="absolute inset-0 flex flex-col items-center justify-center gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1-11a1 1 0 112 0v4a1 1 0 11-2 0V7zm0 6a1 1 0 112 0v1a1 1 0 11-2 0v-1z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-white text-xl">Video Not Available</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingVideo;
