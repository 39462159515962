import React, { useState, useEffect } from "react";
import {
  Card,
  List,
  Typography,
  Space,
  Tag,
  Button,
  Empty,
  Spin,
  message,
} from "antd";
import {
  UserOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { MeetingParticipant, Meetings, Recording } from "../../../type/type";
import { getAllMeetings } from "../../../lib/api";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/root";
import { useHistory, useParams } from "react-router-dom";

const { Title, Text } = Typography;

// You can move these interfaces to a separate types file
interface MeetingListProps {
  onPlayVideo?: (meeting: Meetings) => void;
  onViewDetails?: (meeting: Meetings) => void;
  applicationId: string;
}

const MeetingList: React.FC<MeetingListProps> = ({
  applicationId,
}) => {
  const [meetings, setMeetings] = useState<Meetings[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedMeetingId, setSelectedMeetingId] = useState<string | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();
  const params: { board_id: string; card_id: string } = useParams();

  const currentApplicant: any = useSelector(
    (state: RootState) => state.opening.currentApplicant
  );

  const fetchMeetings = async () => {
    try {
      setLoading(true);
      setError(null);
      // Replace with your actual API endpoint
      getAllMeetings(currentApplicant.id).then((meetings) => {
        setMeetings(meetings || []);

        // Set first meeting as selected if available
        if (meetings && meetings.length > 0 && !selectedMeetingId) {
          setSelectedMeetingId(meetings[0].id);
        }
      });
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : "Failed to fetch meetings";
      setError(errorMessage);
      message.error("Failed to load meetings");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMeetings();
  }, [currentApplicant]);

  const getParticipantsSummary = (participants: MeetingParticipant[]) => {
    const hostName =
      participants.find((p) => p.is_host)?.name || "Unknown Host";
    const totalParticipants = participants.length;
    return { hostName, totalParticipants };
  };

  const getRecordingDuration = (recordings: Recording[]) => {
    if (!recordings || recordings.length === 0) return "N/A";
    const recording = recordings[0];
    if (!recording.started_at || !recording.completed_at) return "N/A";

    const start = dayjs(recording.started_at);
    const end = dayjs(recording.completed_at);
    const durationMinutes = end.diff(start, "minute");
    return `${Math.floor(durationMinutes / 60)}h ${durationMinutes % 60}m`;
  };

  const getMeetingTitle = (meeting: Meetings) => {
    return (
      meeting.meeting_metadata.title ||
      `Meeting on ${dayjs(meeting.created_at).format("MMM D, YYYY")}`
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Spin size="large" tip="Loading meetings..." />
      </div>
    );
  }

  if (error) {
    return (
      <Card>
        <Empty
          description={
            <Space direction="vertical" align="center">
              <Text type="danger">{error}</Text>
              <Button type="primary" onClick={fetchMeetings}>
                Retry
              </Button>
            </Space>
          }
        />
      </Card>
    );
  }

  if (meetings.length === 0) {
    return (
      <Card>
        <Empty description="No meetings found" />
      </Card>
    );
  }

  const backToMeeting = (id: string) => {
    history.push({
      pathname: `/o/${applicationId}/b/${params.board_id}/tracker/${params.card_id}/meeting/${id}`,
    });
  };

  return (
    // <div className="flex flex-col  gap-6 px-2 py-4">
    //   {/* Left side - Meeting list */}
      <div className="flex px-2 py-2">
        <List
          dataSource={meetings}
          renderItem={(meeting) =>
            meeting.tb_video_url !== null && (
              <Card
                className={`mb-4 flex cursor-pointer transition-shadow w-full ${
                  selectedMeetingId === meeting.bot_id
                    ? "border-blue-500 shadow"
                    : "hover:shadow"
                }`}
                style={{ padding: "0", width: "100%" }}
                onClick={() => {
                  setSelectedMeetingId(meeting.id);
                  backToMeeting(meeting.id);
                }}
              >
                {/* Responsive layout for video and info */}
                <div className="w-full flex flex-col 2xl:flex-row gap-4">
                  {/* Video Section */}
                  <div className="w-full 2xl:w-1/3">
                    <video width="100%" controls>
                      <source
                        key={meeting.tb_video_url}
                        src={meeting.tb_video_url}
                        type="video/mp4"
                      />
                      <track
                        kind="captions"
                        srcLang="en"
                        label="English"
                        default
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  {/* Info Section */}
                  <div className="flex-1 min-w-0">
                    <Space direction="vertical" size="small" className="w-full">
                      <Space className="w-full justify-between">
                        <Title level={5} className="m-0">
                          {getMeetingTitle(meeting)}
                        </Title>
                        <Tag
                          color={
                            meeting.recordings?.length > 0 ? "green" : "orange"
                          }
                        >
                          {meeting.recordings?.length > 0
                            ? "Recorded"
                            : "Processing"}
                        </Tag>
                      </Space>

                      <Space wrap>
                        <Tag icon={<UserOutlined />}>
                          {
                            getParticipantsSummary(meeting.meeting_participants)
                              .totalParticipants
                          }{" "}
                          Participants
                        </Tag>
                        <Tag icon={<ClockCircleOutlined />}>
                          {getRecordingDuration(meeting.recordings)}
                        </Tag>
                      </Space>

                      <Text type="secondary" className="line-clamp-2">
                        Host:{" "}
                        {
                          getParticipantsSummary(meeting.meeting_participants)
                            .hostName
                        }
                      </Text>

                      <Text type="secondary" className="text-xs">
                        Created:{" "}
                        {dayjs(meeting.created_at).format("MMM D, YYYY h:mm A")}
                      </Text>
                    </Space>
                  </div>
                </div>
              </Card>
            )
          }
        />
      </div>

    //   Right side - Selected meeting details
    //   <div className="xxl:w-1/2">
    //     {selectedMeetingId && (
    //       <Card className="sticky top-6">
    //         {meetings
    //           .filter((meeting) => meeting.id === selectedMeetingId)
    //           .map((meeting) => (
    //             <div key={meeting.id} className="space-y-4">
    //               <Space direction="vertical" size="large" className="w-full">
    //                 <div>
    //                   <Title level={4} className="m-0">
    //                     {getMeetingTitle(meeting)}
    //                   </Title>
    //                   <Text type="secondary">
    //                     {dayjs(meeting.created_at).format(
    //                       "MMMM D, YYYY h:mm A"
    //                     )}
    //                   </Text>
    //                 </div>

    //                 <Space wrap>
    //                   <Tag color="blue">ID: {meeting.record_id}</Tag>
    //                   <Tag color="green">
    //                     Duration: {getRecordingDuration(meeting.recordings)}
    //                   </Tag>
    //                 </Space>

    //                 <div>
    //                   <Title level={5}>Participants</Title>
    //                   <List
    //                     size="small"
    //                     dataSource={meeting.meeting_participants}
    //                     renderItem={(participant) => (
    //                       <List.Item>
    //                         <Space>
    //                           <UserOutlined />
    //                           <Text>{participant.name}</Text>
    //                           {participant.is_host && (
    //                             <Tag color="blue">Host</Tag>
    //                           )}
    //                         </Space>
    //                       </List.Item>
    //                     )}
    //                   />
    //                 </div>

    //                 <div>
    //                   <Title level={5}>Status History</Title>
    //                   <List
    //                     size="small"
    //                     dataSource={meeting.status_changes}
    //                     renderItem={(status) => (
    //                       <List.Item>
    //                         <Space>
    //                           <Tag
    //                             color={
    //                               status.code === "success" ? "green" : "blue"
    //                             }
    //                           >
    //                             {status.code}
    //                           </Tag>
    //                           <Text>{status.message}</Text>
    //                           <Text type="secondary">
    //                             {dayjs(status.created_at).format("h:mm A")}
    //                           </Text>
    //                         </Space>
    //                       </List.Item>
    //                     )}
    //                   />
    //                 </div>

    //                 <Space className="w-full">
    //                   <Button
    //                     type="primary"
    //                     icon={<PlayCircleOutlined />}
    //                     onClick={() => onPlayVideo?.(meeting)}
    //                     disabled={!meeting.recordings?.length}
    //                     block
    //                   >
    //                     Play Recording
    //                   </Button>
    //                   <Button
    //                     icon={<InfoCircleOutlined />}
    //                     onClick={() => onViewDetails?.(meeting)}
    //                     block
    //                   >
    //                     View Details
    //                   </Button>
    //                 </Space>
    //               </Space>
    //             </div>
    //           ))}
    //       </Card>
    //     )}
    //   </div>
    // </div>
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
});
export default connect(mapStateToProps)(MeetingList);
