import { billingMember, board_role, CDBMember, JDMember, companyMember } from "../../constant"
import { Integration, Notification, Role, Source, newOrganisation } from "../../lib/apiRes"
import {
  OrganizedBoard,
  loginUser,
  User,
  hasError,
  // wsAction,
  wsActionHash,
  wsConn,
  orgStatus,
  currentWsRequest,
  OrgFilterType,
  orgMember,
  SlackOptions,
  ArchivedApplicant,
  InitComments,
  InitLists,
  InitLabel,
  InitOpeningActivity,
  OutgoingInvitations,
  billingUser,
  KeyWithArray,
  InitCardRewards,
  InitAllTpRewards,
  Opening,
  Applicant,
  CandidatesInReview,
  CandidateDetailReview,
  OpeningMembers,
  TableColumn,
  currentCalendar,
  CardAttachment,
  ReviewAttachments,
  ReviewComments,
  openingMemberType,
  initCDbMembersCDB,
  Attachment,
  RecordThreads,
  MailPreview,
  Domain,
  Mail,
  NotificationOptions,
  notification,
  initPluginDataType,
  initOpeningNotifications,
  Jd,
  searchDynamicQuery,
  JobBoard,
  wsAction,
  notificationsPagination,

  FormBuilder,
  Candidate,
  companyBoards,
  TotalBoards,
  eSignDetails,
} from "../../type/type.d";

/********************************************************** initialise type **************************************************************/
let initialOpenings: OrganizedBoard = {
  pageSection: {
    sectionName: "",
    openings: {},
  },
}
export let WorkspaceBoards: { [key: string]: companyBoards } = {};
export let integratedSlackMembers: { [key: string]: Integration[] } = {}
let initMembership: orgMember = {};
let initLabels: InitLabel = {};
export let initCompanies: { [key: string]: JobBoard } = {}
export let initSources: { [key: string]: Source } = {}
export let jobPlatform: { [key: string]: string } = {}
export let applicantStatus: { [key: string]: Candidate[] } = {}
export let relevancyFilters: { name: string; count: number } = {
  name: "created_at",
  count: 0,
};
export let initAnalyticsLabels:{}=
{};
export let defaultOpening: Opening = {
  id: "",
  name: "",
  color: "",
  description: "",
  image: "",
  member_id: "",
  members: [],
  labels: [],
  background_image: false,
  member_only: false,
  visibility: false,
  company_id: "",
  company_name: "",
  job_id: "",
  archive: false,
  starred: false,
  lists: {},
  created_at: "",
  updated_at: "",
  due_date: "",
  priority: "",
  tenant_id: "",
  socket_client_id: "",
  default_template: false,
  total_in_pipeline: 0,
  access: {
    admin: false,
    comment_only: false,
    is_secrete_visible: false

  },
  analytics: {
    "sourced": 0,
    "offered": 0,
    "joined": 0,
    "rejected": 0
  }
};
let InitCurrentTracker: Jd = {
  id: "",
  title: "",
  description: "",
  company_name: "",
  locations: [],
  attachment_url: "",
  number_of_open_positions: 0,
  skills_description: "",
  employment_status: "",
  experience_level_from: 0,
  experience_level_to: 0,
  experience_level_in_string: "",
  salary_currency_type: "",
  salary_type: "",
  salary_range_from: 0,
  salary_range_to: 0,
  status: "",
  form_id: "",
  form_name: "",
  form_url: "",
  job_type: "",
  jd_public_url: "",
  board_id: "",
  state: "",
  country: "",
  city: "",
  creater_id: "",
  country_code: "",
  updated_at: "",
  created_at: "",
  hide: false,
  department: ""
}
export let InitCurrentTrackerForm: FormBuilder = {
  id: "",
  title: "",
  form_data: { elements: [] },
  form_url: "",
  created_at: "",
  updated_at: "",
  status: "",
  creater_id: "",
  company_logo: ""
}
let InitCardReward: InitCardRewards = {};
let InitAllTpReward: InitAllTpRewards = {};
export let InitCurrentApplicant: Applicant = {
  id: "",
  name: "",
  description: "",
  archive: false,
  starred: false,
  created_at: "",
  updated_at: "",
  list_id: "",
  opening_id: "",
  member_only: true,
  socket_client_id: "",
  index: 0,
  comment_count: 0,
  attachment_count: 0,
  labels: [],
  members: [],
  creator: "",
  position: 0,
  rank: "",
  access: {
    admin: true,
    comment_only: false,
    is_secrete_visible: false,
  },
  candidate_id: "",
  opening_name:"",
  workspace_name:'',
};
export let InitCurrentApplicantMailPreview: { [key: string]: MailPreview[] } =
  {};
export let InitCurrentApplicantMailThread: { [key: string]: RecordThreads[] } =
  {};

let clientId: string = "";
let initComments: InitComments = {};
export let initOpeningActivity: InitOpeningActivity = {};
let outgoingInvitations: OutgoingInvitations[] = [];
let incomingInvitations: OutgoingInvitations[] = [];
let archivedApplicant: ArchivedApplicant = {};
let applicantLabels: KeyWithArray = {};
let applicantMembers: KeyWithArray = {};
let openingMembers: OpeningMembers = {};
export type servicesMembership = {
  [CDBMember]: openingMemberType[];
  [billingMember]: openingMemberType[];
  [JDMember]: openingMemberType[],
  [companyMember]: openingMemberType[]
}
  

export const InitservicesMembership: servicesMembership = {
  [CDBMember]: [],
  [billingMember]: [],
  [JDMember]: [],
  [companyMember]: []
}

export type OrgDomain = {
  [key: string]: Domain;
};
export let defaultDomain: OrgDomain = {};
let initLists: InitLists = {};
let attachment: CardAttachment = {};
export let initUser: User = {
  connectorId: "",
  id: "",
  lastLoginInstant: 0,
  passwordChangeRequired: false,
  passwordLastUpdateInstant: 0,
  uniqueUsername: "",
  username: "",
  usernameStatus: "",
  verified: false,
  active: false,
  email: "",
  data: { verifiedPhone: false, locked: false },
  firstName: "",
  imageUrl: "",
  insertInstant: 0,
  lastName: "",
  lastUpdateInstant: 0,
  mobilePhone: "",
  registrations: [
    {
      applicationId: "",
      data: { type: false, description: "", key: "", logo: "" },
      id: "",
      insertInstant: 0,
      lastLoginInstant: 0,
      lastUpdateInstant: 0,
      roles: [],
      username: "",
      usernameStatus: "",
      verified: false,
    },
  ],
  tenantId: "",
  twoFactor: {},
  timezone: "",
};

export let careerPageDetails: { [key: string]: string } = {
  mission_statement: "",
  website: "",
  location: "",
  company_size: "",
  linkedin: "",
  twitter: "",
  facebook: "",
  insta: "",
  youtube: "",
};

let initLoginUser: loginUser = {
  token: "",
  user: initUser,
  services: [],
};
let initPluginData: initPluginDataType = {
  mappings: [
    {
      id: "",
      app_name: "",
      app_name_view:"",
      mapping: [
        {
          tacit_col: "",
          tacit_col_view: "",
          plugin_col: "string",
          plugin_col_view: "string",
        },
      ],
    },
  ],
};

let attachmentLoader: boolean = false;
let topicSubscription: any = {};
let Error: hasError = {
  error: false,
  message: "",
};
let archiveList: KeyWithArray = {};
let application_id: string = "";
let application_name: string = "";
let slackOptions: SlackOptions = {
  id: "",
  userid: "",
  topic: "",
  subscriber: "",
  socket_client_id: "",
  setting: {
    attachment: [],
    board: [],
    card: [],
    comment: [],
    description: [],
    label: [],
    list: [],
    member: [],
  },
  created_at: "",
  updated_at: "",
};

export let notificationOptions: NotificationOptions = {
  id: "",
  userid: "",
  topic: "",
  subscriber: "",
  socket_client_id: "",
  setting: {
    select_all: [],
    comments: [],
    add_remove_from_card_board: [],
    attachment_create: [],
    card_create: [],
    card_move: [],
    card_archive: [],
  },
  email_frequency: [], sound_settings :{enabled:false,selected_sound:""},
  created_at: "",
  updated_at: "",
};
let BoardFilterKeys: OrgFilterType = {};


export let searchQuery: searchDynamicQuery = {
  query: "",
  filters: []
}

export let JobPostPoolSearchQuery: searchDynamicQuery = {
  query: "",
  filters: []
}

let orgRoles: Role[] = board_role;
let Roles: string[] = [];
let sortingTypePerOrg = {};
// websocket
let websocketActionRequestQueue: wsAction[] = []
let websocketPrcessedActionRequestQueue: wsActionHash = {};
export let candidateDB: initCDbMembersCDB = {};
export let  initCandidatePool: initCDbMembersCDB = {};

export let currentCDBactivity: {
  [key: string]: {};
} = {};
export let reviewAttachments: ReviewAttachments = {};
export let reviewComments: ReviewComments = {};
export let reviewLabels: any = {};
export type userMails = {
  [key: string]: {
    [key: string]: {
      [key: string]: MailPreview;
    };
  };
};
export let usermailPreview: userMails = {};
export type currentMailThreads = {
  [key: string]: RecordThreads;
};
export type currentCDBCandidateMails = {
  [key: string]: { [key: string]: MailPreview };
};
export let currentCandidateMails: currentCDBCandidateMails = {};

export type initThreadOfCard = {
  [key: string]: Mail[];
};
export let currentJD: Jd = {
  id: "",
  title: "",
  description: "",
  company_name: "",
  locations: [],
  attachment_url: "",
  number_of_open_positions: 0,
  skills_description: "",
  job_type: "",
  experience_level_from: 0,
  experience_level_to: 0,
  experience_level_in_string: "",
  salary_currency_type: "",
  salary_range_from: 0,
  salary_range_to: 0,
  salary_type: "",
  created_at: "",
  updated_at: "",
  creater_id: "",
  status: "",
  form_id: "",
  form_name: "",
  form_url: "",
  jd_public_url: "",
  board_id: "",
  country: "",
  country_code: "",
  state: "",
  city: "",
  hide: false,
  department: ""
}
export let currentJDReviewer: KeyWithArray = { reviewers: [] }
export type initDashboardNotification = {
  [key: string]:  Notification ;
};

export let currentMailThread: currentMailThreads = {};
export let currentCandidateMailThread: currentMailThreads = {};

export let InitCandidatesInReview: CandidatesInReview = {
  reviewed: false,
  reviews: {},
  currentPage:"0"
};
export let InitReviewAttachments: ReviewAttachments = {};
export type CurrentCandidateInEditMode = {
  candidateId: string;
  labels: any[];
  attachments: Attachment[];
};
export type CurrentCandidateInPool = {
  candidate: Candidate;
  labels: any[];
  attachments: Attachment[];
  newAttachmentUploader?:{uploading: boolean,
    fileList: any,
    uploadResponse: any,
    uploadError: any,
  }
};
export let InitCurrentCandidateInEditMode: CurrentCandidateInEditMode = {
  candidateId: "",
  labels: [],
  attachments: [],
};
export let InitcurrentCandidateInPool: CurrentCandidateInPool = {
  candidate:{
    "id": "",
    "first_name": "",
    "last_name": "",
    "description": "",
    "email": "",
    "phone": "",
    "country_code": "",
    "extension": "",
    "source": {
      "id": "",
      "from": "",
      "expiry": "",
      "jd": "",
      "created_at": "",
      "updated_at": "",
      "medium":"",
    },
    "customs": {},
    "attached_jd": "",
    "socket_client_id": "",
    "created_at": "",
    "updated_at": "",
    "skills": "",
    labels:[]
  } ,
  labels: [],
  attachments: [],
  newAttachmentUploader:{uploading: false,
  fileList: null,
  uploadResponse: null,
  uploadError: null,
}};
export let InitCurrentCandidateInReview: CandidateDetailReview = {
  id: "",
  name: "",
  candidates: {
    id: "",
    first_name: "",
    last_name: "",
    linked_in: "",
    twitter: "",
    source: {
      name: "",
      from: "",
      created_at: 0,
      medium:"",
    },
    description: "",
    email: "",
    phone: "",
    customs: {}
  },
  description: "",
  labels: [],
  members: [],
  attachment_count: 0,
  created_by: "",
  comment_count: 0
};
// billing
let initBillingUser: billingUser = {
  blockFromCardCreation: false,
};
export let initCDBTabelColumn: TableColumn[] = [];

export let initialNotificationObj :initOpeningNotifications[]=[];

export let initialWatcher = {
  id: "",
  entity: "",
  entity_id: "string",
  parent_entity: "string",
  parent_entity_id: "string",
  user_id: "string",
  org_id: "string",
};
// notification
export let notificationObj: notification = {
  allNotifications: initialNotificationObj,
  unreadNotifications: initialNotificationObj,
  entityUnreadNotification: initialNotificationObj,
  entityUnreadNotificationBoard:initialNotificationObj,
  dashboardNotificationsDot: {},
  boardNotificationsDot:{},
  cardNotificationsDot:{},
  reviewNotificationsDot:{},
  mailNotificationsDot:{},
  cardNotifications: {},
  currentCardWatcher: initialWatcher,
  currentBoardWatcher: initialWatcher,
};


export let notitifcationCenterObj:unreadDotsofServicesType={
  showDotOnUpdates:false,
  unread:[],
  unreadDotsofServices:{
ats:{
    boards:{},cards:{}
},review:{
  reviews:{}
}
,inbox:{
  mail:{}
}

  }
}

type unreadDotsofServicesType={
  showDotOnUpdates:boolean,
  unread:[],
  unreadDotsofServices:{
    [key: string]: { // Index signature for dynamic keys
      [key: string]: {} // Nested index signature
    };

  }
}

// calendar
export let current_calendar: currentCalendar = {
  currentCalenderId: "",
  rrules:{},
  currentCalendarConferenceSettings: {
    zoom_id: "",
    zoom_user_id: "",
    zoom_email: "",
    meet_id: "",
    meet_user_id: "",
    meet_email: "",
  },
  currentUserZoomMeet: {
    zoom_email: "",
    google_email: "",
  },
  currentCalendar: {
    id: "",
    name: "",
    description: "",
    owner_id: "",
    owner_name: "",
    owner_email: "",
    default_timezone: "",
    default_event_duration: 0,
    default_event_color: "",
    past_event_color: true,
    board_id: "",
    org_id: "",
    member_only: false,
    created_at: "",
    updated_at: "",
    access: null,
  },
  currentCardEvents: [],
  currentBoardEvents: [],
};
export let  initBillingCustomer ={
  status:{
    stripe_cust_id:
    "",setup:{payment_method:false,address:false}
  },
  selectedPlanObj:{}
}
export const initialUpdatesPagination:notificationsPagination ={
  page: 0,
per_page: 10,
page_count: 0,
total_count: 0

}
export const initCurrentOrg:newOrganisation = {
  "id": "",
  "name": "",
  "user_id": "",
  "career_page_url_suffix":"",
  "display_name":"",
  "type": false,
  "services": null,
  "roles": null,
  "user": {
    "registration": {
      "verified": false
    },
    "user": {
      "passwordChangeRequired": false,
      "verified": false,
      "active": false,
      "twoFactor": {}
    }
  },
  "application": {
    "accessControlConfiguration": {},
    "active": false,
    "authenticationTokenConfiguration": {
      "enabled": false
    },
    "cleanSpeakConfiguration": {
      "enabled": false,
      "usernameModeration": {
        "enabled": false
      }
    },
    "data": {
      type: false,
    description: "",
    key: "",
    logo: ""
    },
    "emailConfiguration": {},
    "externalIdentifierConfiguration": {},
    "formConfiguration": {
      "adminRegistrationFormId": ""
    },
    "id": "",
    "insertInstant": 0,
    "jwtConfiguration": {
      "enabled": false,
      "refreshTokenRevocationPolicy": {
        "onLoginPrevented": false,
        "onPasswordChanged": false
      }
    },
    "lambdaConfiguration": {},
    "lastUpdateInstant": 0,
    "loginConfiguration": {
      "allowTokenRefresh": false,
      "generateRefreshTokens": false,
      "requireAuthentication": false
    },
    "multiFactorConfiguration": {
      "email": {},
      "sms": {}
    },
    "name": "",
    "oauthConfiguration": {
      "debug": false,
      "generateRefreshTokens": false,
      "requireClientAuthentication": false,
      "requireRegistration": false
    },
    "passwordlessConfiguration": {
      "enabled": false
    },
    "registrationConfiguration": {
      "enabled": false,
      "birthDate": {
        "enabled": false,
        "required": false
      },
      "confirmPassword": false,
      "firstName": {
        "enabled": false,
        "required": false
      },
      "fullName": {
        "enabled": false,
        "required": false
      },
      "lastName": {
        "enabled": false,
        "required": false
      },
      "loginIdType": "",
      "middleName": {
        "enabled": false,
        "required": false
      },
      "mobilePhone": {
        "enabled": false,
        "required": false
      },
      "type": ""
    },
    "registrationDeletePolicy": {
      "unverified": {
        "enabled": false,
        "numberOfDaysToRetain": 0
      }
    },
    "roles": [],
    "samlv2Configuration": {
      "enabled": false,
      "debug": false,
      "logout": {
        "requireSignedRequests": false,
        "singleLogout": {
          "enabled": false
        }
      },
      "requireSignedRequests": false
    },
    "state": "",
    "tenantId": "",
    "unverified": {
      "behavior": ""
    },
    "verifyRegistration": false
  },
  "customer": null,
  "billing_intent": null,
  "setup_stages": [
   
  ],
  "created_at": "",
  "UpdatedAt": "",
  "subscriptions": null,
  "dashboard": {
    configuration:{
      active: false,
    application: null,
    business_profile: null,
    created: 0,
    default_return_url: "",
    features: null,
    id: "",
    is_default: false,
    livemode: false,
    login_page: null,
    metadata: null,
    object: "",
    updated: 0,
    },
  created: 0,
  customer: "",
  flow: null,
  id: "",
  livemode: false,
  locale: "",
  object: "",
  on_behalf_of: "",
  return_url: "",
  url: ""
  },
  "status": ""  
};

export const initChatsIntegration:Integration[] = []
export const InitOrgTotalBoard:TotalBoards = {
  active:0,
  total:0
}

export const InitESignDetails:eSignDetails={
  connected: false,
  tools: [],
  documents: [],
  eSignDoc:[],
  allDocuments: [],
}

export const JdData=[]

/********************************************************************* root state  ***********************************************************/

export const initialState = {
  totalBoards:InitOrgTotalBoard,
  loginUser: initLoginUser,
  careerPageDetails:careerPageDetails,
  pluginData: initPluginData,
  notificationCenter:notitifcationCenterObj,
  notification: notificationObj,
  chatsIntegration:initChatsIntegration,
  billingCustomer:initBillingCustomer,
  billingUser: initBillingUser,
  websocket: false,
  currentOrg:initCurrentOrg,
  filledFeedbackForm: false,
  applicationId: application_id,
  applicationName: application_name,
  clientId: clientId,
  companyBoards: WorkspaceBoards,
  openings: initialOpenings,
  currentOpening: defaultOpening,
  currentTrackerJd: InitCurrentTracker,
  currentTrackerForm: InitCurrentTrackerForm,
  calendar: current_calendar,
  boardList: initLists,
  members: initMembership,
  labels: initLabels,
  comments: initComments,
  openingActivity: initOpeningActivity,
  collapseLabel: true,
  showSearch: false,
  archivedApplicants: archivedApplicant,
  attachment: attachment,
  attachmentLoader: attachmentLoader,
  currentApplicant: InitCurrentApplicant,
  currentApplicantMailPreview: InitCurrentApplicantMailPreview,
  currentApplicantMailThread: InitCurrentApplicantMailThread,
  applicantLabels: applicantLabels,
  applicantMembers: applicantMembers,
  openingMembers: openingMembers,
  topicSubscription: topicSubscription,
  archiveList: archiveList,
  outgoingInvitations: outgoingInvitations,
  incomingInvitations: incomingInvitations,
  hasSomeError: Error,
  reloadActivity: false,
  slackOptions: slackOptions,
  notificationOptions: notificationOptions,
  websocketActionRequestQueue: websocketActionRequestQueue,
  websocketPrcessedActionRequestQueue: websocketPrcessedActionRequestQueue,
  wsConnection: wsConn,
  orgStatus: orgStatus,
  currentWsRequest: currentWsRequest,
  filterCount: null,
  boardFilterKeys: BoardFilterKeys,
  roles: Roles,
  orgRoles: orgRoles,
  sortingType: sortingTypePerOrg,
  currentApplicantRewards: InitCardReward,
  allTpRewards: InitAllTpReward,
  candidateDB: candidateDB,
  currentCDBactivity: currentCDBactivity,
  currentCDBAtsActivity: currentCDBactivity,
  candidateDBTableHeader: initCDBTabelColumn,
  candidatesInReview: InitCandidatesInReview,
  currentCandidateInReview: InitCurrentCandidateInReview,
  currentCandidateInEditMode: InitCurrentCandidateInEditMode,
  currentCandidateInPool: InitcurrentCandidateInPool,
  candidatePool: initCandidatePool,

  candidateLabels: initLabels,
  servicesMembership: InitservicesMembership,
  reviewAttachments: reviewAttachments,
  reviewComments: reviewComments,
  reviewLabels: reviewLabels,
  userMails: usermailPreview,
  currentMailThread: currentMailThread,
  defaultDomain: defaultDomain,
  currentCandidateMailThread: currentMailThread,
  currentCandidateMails: currentCandidateMails,
  currentJD: currentJD,
  currentJDReviewer: currentJDReviewer,
  searchQuery: searchQuery,
  companies: initCompanies,
  analayticsLabels:initAnalyticsLabels,
  integratedSlackMember: integratedSlackMembers,
  pageAndContentLoader:{
    loader:false,message:"",skeleton:false
  },
  candidateSources: initSources,
  jobPlatform: jobPlatform,
  applicantStatus: applicantStatus,
  eSignDetails: InitESignDetails,
};
