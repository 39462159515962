import React from "react";
import InvitedSignupIndex from "../../components/signUp/InvitedSignupIndex";
import BrandingPanel from "../../components/signUp/BrandingPanel";

const InvitedSignUpPageLayout: React.FC = () => {
  return (
    <div className="flex min-h-screen max-h-screen overflow-hidden ">
      <BrandingPanel />
      <InvitedSignupIndex/>
    </div>
  );
};

export default InvitedSignUpPageLayout;
