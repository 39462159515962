import React from "react";
import OrgCreationStatus from "../../pages/signUp/OrgCreationStatus";
import OrgPreviewSection from "./OrgPreviewSection";
const OrgSetup = () => {
  return (
    <div className="flex flex-col lg:flex-row h-screen w-full bg-white">
      <div className="lg:block w-full h-full lg:rounded ">
        <div className=" flex justify-center items-center h-full w-full">
          <OrgPreviewSection renderComponent={OrgCreationStatus} />
        </div>
      </div>
    </div>
  );
};

export default OrgSetup;
