import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Spin, Typography } from 'antd'
import Title from 'antd/es/typography/Title'
// import PageLoader from '../../components/PageLoader'
import { getOrgDetails, InterviewerLogin, setAnyTokenInSessionStorage, setTokenInLocalStorage } from '../../lib/api'
import { loginUserAc, updateOrgNameId } from '../../redux/actions/opening/action'
import { orgtokenVerify } from '../../service/tokenVerify'
import Deactivated from './Deactivated'
import { Dispatch } from 'redux'
const { Text } = Typography

const Login = (props: {
  applicationId: string, loginUserAc(token: any, userData: any): void; UpdateOrgNameId(orgData: any): void;

}) => {
  const { base64String } = useParams<{ base64String: string }>()
  const [loading, setLoading] = useState(false) // New state for loading
  const [isInvalidUser, setIsInvalidUser] = useState(false)
  const [isDeactivated, setIsDeactivated] = useState(false)
  //   const [isPwdExpired, setIsPwdExpired] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const performLogin = async () => {
      setLoading(true)
      try {
        InterviewerLogin(base64String)
          .then(async (loginResponse) => {
            setTokenInLocalStorage("login_token", loginResponse.token)
            // Store token in session storage
            setAnyTokenInSessionStorage('current_tab_application_token', loginResponse.token).then(() => {
              props.loginUserAc(loginResponse.token, loginResponse.user);
            })

            getOrgDetails(loginResponse.user.registrations[0].applicationId).then((appRes) => {
              localStorage.setItem("orgId",appRes.application.id);
              let updatedOrg = {
                applicationId: loginResponse.user.registrations[0].applicationId,
                id: loginResponse.user.registrations[0].applicationId,
                applicationName: loginResponse.user.registrations[0].username,
                applicationImgUrl: loginResponse.user.registrations[0].data?.logo,
                verified: true,
                data: {
                  logo: '',
                  description: ''
                },
                roles: loginResponse.user.registrations[0].roles[0]
              }
              props.UpdateOrgNameId(updatedOrg)
              setTimeout(() => {
                setLoading(false)
              }, 2000)
              history.push(`/o/${loginResponse.user.registrations[0].applicationId}/meetings`)
            })
          })
          .catch((error) => {
            setTimeout(() => {
              setLoading(false)
            }, 2000)
            if (error.response && error.response.status === 401) {
              setAnyTokenInSessionStorage('sessionKey', base64String).then(() => {
                return true
              })
              // setIsPwdExpired(true)
            } else if (error.response && error.response.status === 404) {
              setIsInvalidUser(true)
            } else if (error.response && error.response.status === 410) {
              setIsDeactivated(true)
            }
          })
      } catch (error) {
        console.log("error",error)
      }
    }
    if (base64String) {
      performLogin()
    } else if (orgtokenVerify() && props.applicationId) {
      history.push(`/o/${props.applicationId}/meetings`)
    } else {
      setLoading(false)
      history.push(`/page-not-found`)
    }
  }, [base64String])

  return (
    <>
      {loading ? (
        <div className='flex items-center justify-center min-h-screen bg-gray-50 p-4'>
          <div className='max-w-md w-full bg-white shadow-lg rounded-lg p-6 sm:p-8 text-center'>
            <Spin size='large' className='mb-4' />

            <h1 className='text-xl text-gray-800 mb-4'>Processing your login</h1>

            <Text className='text-gray-600 mb-4'>Please wait while we authenticate your credentials. This may take a few moments.</Text>
          </div>
        </div>
      ) : isInvalidUser ? (
        <div className='flex items-center justify-center min-h-screen bg-gray-50 p-4'>
          <div className='max-w-md w-full bg-white shadow-lg rounded-lg p-6 sm:p-8 text-center'>
            <Title level={3} className=' mb-4'>
              Authentication Failed
            </Title>
            <Text className='text-gray-600 mb-4'>
              We were unable to verify your credentials. This could be due to an expired link. Please check the link you used or{' '}
              <a href='https://support.tacitbase.com/' style={{ color: '#009D79' }}>
                contact support
              </a>{' '}
              if the problem persists.
            </Text>{' '}
          </div>
        </div>
      ) : isDeactivated ? (
        <Deactivated />
        //   ) : isPwdExpired ? (
        //     <GenerateNew />
      ) : (
        // <PageLoader loaderIcon={undefined} content={undefined} footer={undefined} />

        <div>nothing is here</div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId
})
const mapPropsTostate = (dispatch: Dispatch) => ({
  loginUserAc: (token: any, userData: any) =>
    dispatch(loginUserAc(token, userData)),
  UpdateOrgNameId: (orgData: any) => dispatch(updateOrgNameId(orgData)),

});
export default connect(mapStateToProps, mapPropsTostate)(Login)
