import React from "react"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { BACK_TO_LOGIN, RESET_PWD_LINK_ADDITIONAL_HEADING, RESET_PWD_LINK_HEADING } from "../../constant"
import {Typography} from 'antd'
const {Link,Text} = Typography


const ExpireLinkMsg = () => {
    return (
      <div>
        <div className="w-full max-w-md space-y-4">
        <Text className="lg:text-2xl text-xl  font-bold bg-gradient-to-r text-tacitbase-secondary3">
            {capitalizeFirstLetter("Password reset")}
          </Text>
          <div className="text-gray-600">{RESET_PWD_LINK_HEADING}</div>
          <div>
            To regain access to your account, get a new link by trying{" "}
            <Link href="/password/change">
              {capitalizeFirstLetter("Forget password")}
            </Link>{" "}
            again.
          </div>

          <div>{RESET_PWD_LINK_ADDITIONAL_HEADING}</div>
          <Link href={`/login`}>{BACK_TO_LOGIN} </Link>
        </div>
      </div>
    );
}
export default ExpireLinkMsg
