import React from 'react';
import TacitbaseAvtar from '../../ant/Avtar';

interface Word {
    text: string;
}

interface TranscriptEntry {
    words: Word[];
    speaker: string
}

// interface Comment {
//     text: string;
//     selectedText: string;
// }

interface VideoTranscriptProps {
    transcript: TranscriptEntry[];
}

const VideoTranscript: React.FC<VideoTranscriptProps> = ({  transcript }) => {
    // const [selectedText, setSelectedText] = useState<string>('');
    // const [comments, setComments] = useState<Comment[]>([]);

    // const handleTextSelection = () => {
    //     const selection = window.getSelection();
    //     if (selection && selection.rangeCount > 0) {
    //         const selected = selection.toString();
    //         if (selected) {
    //             setSelectedText(selected);
    //         }
    //     }
    // };

    // const addComment = (comment: string) => {
    //     setComments([...comments, { text: comment, selectedText }]);
    //     setSelectedText('');
    // };

     // Preprocess the transcript to merge consecutive entries by the same speaker
     const mergeTranscriptEntries = (entries: TranscriptEntry[]): TranscriptEntry[] => {
        const mergedEntries: TranscriptEntry[] = [];
    
        if (!Array.isArray(entries)) {
            console.error("Expected 'entries' to be an array");
            return mergedEntries; // Return empty if entries is not an array
        }
    
        for (const entry of entries) {
            if (!entry || !Array.isArray(entry.words) || typeof entry.speaker !== 'string') {
                console.warn("Invalid entry detected, skipping:", entry);
                continue; // Skip invalid entries
            }
    
            if (
                mergedEntries.length > 0 &&
                mergedEntries[mergedEntries.length - 1].speaker === entry.speaker
            ) {
                // Merge words into the last entry if the speaker is the same
                mergedEntries[mergedEntries.length - 1].words.push(...entry.words);
            } else {
                // Otherwise, add a new entry
                mergedEntries.push(entry);
            }
        }
    
        return mergedEntries;
    };

    const mergedTranscript = mergeTranscriptEntries(transcript);


    return (
        <div className='flex '>
            <div
            //  onMouseUp={handleTextSelection}
               role="button" tabIndex={-1} >
                 {mergedTranscript?.map((entry, index) => (
                    <div key={index} className='mb-6'>
                        <div className='flex gap-2 font-bold items-center mb-1'>                      
                              <TacitbaseAvtar content={entry?.speaker?.[0]} />
                        {entry.speaker}</div> {/* Display speaker name */}
                        <p className='mt-1'>
                            {entry.words.map((word, wordIndex) => (
                                <span key={wordIndex} style={{ userSelect: 'text' }} className=''>
                                    {word.text}{' '}
                                </span>
                            ))}
                        </p>
                    </div>
                ))}
            </div>
            {/* {selectedText && (
                <div>
                    <input
                        type="text"
                        placeholder="Add a comment"
                        onKeyDown={(e:any) => {
                            if (e.key === 'Enter') {
                                addComment(e.target.value);
                                e.target.value = '';
                            }
                        }}
                    />
                </div>
            )}
            <div>
                <h3>Comments:</h3>
                <ul>
                    {comments.map((comment, index) => (
                        <li key={index}>
                            <strong>{comment.selectedText}:</strong> {comment.text}
                        </li>
                    ))}
                </ul>
            </div> */}
        </div>
    );
};

export default VideoTranscript;
