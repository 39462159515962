import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginUser, orgMember, Plan } from "../../../type/type";
import TacitBaseModal from "../../../ant/TacitbaseModal";
import { Typography } from "antd";
import { OWNER, TACITBASE } from "../../../constant";
import { newOrganisation } from "../../../lib/apiRes";
import { getBillingActivePlans } from "../../../lib/api";
import PricingTable from "./PricingTable";

const { Text } = Typography;

type GetplanModelProps = {
  isModalVisible: boolean;
  onCancel: () => void;
  loginUser: loginUser;
  members: orgMember;
  currentOrg: newOrganisation;
};

const GetplanModel = ({
  isModalVisible,
  onCancel,
  loginUser,
  members,
}: GetplanModelProps) => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const basePlans = await getBillingActivePlans(TACITBASE.toLowerCase());
        setPlans([...basePlans?.plans]);
      } catch (error) {
        console.error("Error fetching plans:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPlans();
  }, []);
  return (
    <TacitBaseModal
      className="w-auto animate-fade-in"
      maskClosable={true}
      onCancel={onCancel}
      title={
        <div className="flex flex-col gap- items-center">
            <Text className="text-xl font-semibold mb-1">
             Simple,transparent pricing
            </Text>
          <Text type="secondary">
          Choose the perfect plan for your business needs.
          </Text>
        </div>
      }
      visibility={isModalVisible}
      content={
        <>
          <PricingTable loading={loading} plans={plans} />
          {!loading &&
            (members[loginUser.user.id].registrations[0].roles[0] !== OWNER ? (
              <div className="flex justify-center mt-6">
                <Text style={{ color: "#009D79" }}>
                  {"Please contact your admin."}
                </Text>
              </div>
            ) : null)}
        </>
      }
      width={"auto"}
      footer={null}
      centered
    />
  );
};

const mapStateToProps = (state: any) => ({
  currentOrg: state.opening.currentOrg,
  loginUser: state.opening.loginUser,
  members: state.opening.members,
});

export default connect(mapStateToProps)(GetplanModel);
