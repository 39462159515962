import React from "react";
import {  Button, Result } from "antd";
import { useHistory } from "react-router-dom";
const EmailVerifiedPage: React.FC = () => {
  const history = useHistory();

  const handleLogin = () => {
    history.push("/login");
  };

  return (
    <div className="flex min-h-screen max-h-screen justify-center items-center overflow-hidden">
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="w-full sm:w-full md:w-full lg:w-10/12 xl:9/12 2xl:w-8/12">
          <Result
            status="success"
            title={
              <span className="text-sm" style={{ color: "#009D79" }}>
                Your email has been successfully verified!
              </span>
            }
            subTitle={
              <div className="flex flex-col gap-1">
                <span>Please log in to continue.</span>

                <Button
                  type="link"
                  onClick={handleLogin}
                  style={{ marginTop: "20px", width: "100%" }}
                >
                  Go to Login
                </Button>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default EmailVerifiedPage;
