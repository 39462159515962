import React from "react";
import TB_ORANGE_LOGO from "../../images/TB full orange.png";
import { Image, Rate } from "antd";
import G2 from "../../images/G2.png";
import Capterra from "../../images/Capterra.png";
import Gartner from "../../images/Gartner.png";
import TestimonialCarousel from "./TestimonialCarousel";
import kisai from "../../images/kis.ai.png";
import Last9logo from "../../images/Last9logo.png";
import RelambdaLogo from "../../images/RelambdaLogo.png";
import NonStopLogo from "../../images/NonStopLogo.png";
import GaasLogo from "../../images/GaasLogo.png";
import TwinmoAiLogo from "../../images/TwinmoAiLogo.png";

const BrandingPanel = () => {
  const logos = [
    { name: "kis.ai", logo: kisai },
    { name: "Last9", logo: Last9logo },
    { name: "ReLambda", logo: RelambdaLogo },
    { name: "NonStop", logo: NonStopLogo },
    { name: "GHAAS", logo: GaasLogo },
    { name: "Twinmo", logo: TwinmoAiLogo },
  ];

  return (
    <div className="hidden lg:flex lg:w-1/2 relative bg-tacitbase-secondary3 bg-opacity-5 lg:rounded">
      <div className="absolute inset-0 bg-gradient-to-br from-indigo-500/10 via-purple-500/10 to-pink-500/10">
        <div className="absolute inset-0 backdrop-blur-3xl" />
      </div>

      <div className="relative w-full h-full flex flex-col justify-between overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-start mb-4">
            <img
              src={TB_ORANGE_LOGO}
              alt="Tacitbase Logo"
              className="w-48 lg:w-56"
              style={{ backgroundColor: "transparent" }}
            />
          </div>
        </div>

        <div className="flex-grow flex flex-col justify-center p-6 space-y-6 lg:space-y-8">
        <p className=" text-lg lg:text-2xl text-gray-600 font-semibold">
            One platform for all your hiring needs
          </p>
          <TestimonialCarousel />

          <div className="flex flex-col lg:flex-row items-center justify-center space-y-4 lg:space-y-0 lg:space-x-8">
            <div className="flex flex-col items-center justify-center">
              <Rate disabled defaultValue={5} />
              <Image width={25} src={G2} preview={false} />
            </div>
            <div className="flex flex-col items-center justify-center">
              <Rate disabled allowHalf defaultValue={4.5} />
              <Image width={100} src={Gartner} preview={false} />
            </div>
            <div className="flex flex-col items-center justify-center">
              <Rate disabled defaultValue={5} />
              <Image width={100} src={Capterra} preview={false} />
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="text-center mb-4 text-gray-600">
            Trusted by global founders and hiring professionals
          </div>
          <div className="relative w-full overflow-hidden">
            <div className="flex justify-center space-x-8 lg:space-x-16 2xl:space-x-20 animate-animate_scroll">
              {logos.map((logo, index) => (
                <div
                  key={index}
                  className="flex-shrink-0 flex items-center justify-center h-8 lg:h-10"
                >
                  <img
                    alt={logo.name}
                    src={logo.logo}
                    className="h-6 lg:h-7 object-contain"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandingPanel;
