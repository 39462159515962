import React from "react"
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Tooltip } from 'antd'


type TacitbaseAvtarProps = {
    content?: string,
    src?: string,
    toolTip?: string,
    shape?: 'circle' | 'square',
    size?: 'large' | 'small' | 'default',
    tooltipPlacement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom'
}
const TacitbaseAvtar: React.FC<TacitbaseAvtarProps> = ({ src, shape = "circle", size = "small", toolTip, tooltipPlacement = "top", content }) => (
    <Tooltip title={toolTip} placement={tooltipPlacement}>
        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }} shape={shape} src={src} size={size} icon={src ? null : content ? null : <UserOutlined   />}
        > {typeof content === "string" ? content.toUpperCase() : null}</Avatar>
    </Tooltip>
)

export default TacitbaseAvtar