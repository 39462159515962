import React from "react";
import ContactOwner from "../../components/signUp/ContactOwner";
import OrgPreviewSection from "../../components/signUp/OrgPreviewSection";

const ContactOwnerPage = () => {
  return (
    <div className="flex flex-col lg:flex-row h-screen w-full bg-white">
      <div className="lg:block w-full h-full lg:rounded ">
        <div className=" flex justify-center items-center h-full w-full">
          <OrgPreviewSection renderComponent={ContactOwner} />
        </div>
      </div>
    </div>
  );
};

export default ContactOwnerPage;
