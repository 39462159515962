import React from "react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { Button, Typography } from "antd";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const ContactOwner = () => {
  const history = useHistory();

  const handleNavigationToOrgSwitcher = async () => {
    history.push("/o");
  };

  return (
    <div className="w-full p-6 h-full flex items-center justify-center overflow-y-hidden rounded-md bg-white">
      <div className="space-y-6 border rounded-lg px-6 py-4 mb-4">
        <Text className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
          {capitalizeFirstLetter("Huston, we have a problem")}
        </Text>
        <div className="flex flex-col space-y-4 lg:text-sm text-xs mt-4">
          <div className="tracking-wide">
            Our records indicate that there is an issue. It looks like your
            account administrator is yet to add payment details.
          </div>
          <div className="mt-4">
            To continue enjoying Tacitbase seamlessly, please take a moment to
            update your billing information or contact your account
            administrator.
          </div>
          <div className="mt-4">
            Are you an administrator?{" "}
            <a
              href="https://support.tacitbase.com/"
              style={{ color: "#009D79" }}
            >
              Contact us.
            </a>
          </div>
        </div>
        <div className="flex justify-end w-full mt-6">
          <Button
            htmlType="submit"
            className="w-max"
            onClick={handleNavigationToOrgSwitcher}
            type="primary"
          >
            Go back
          </Button>
        </div>
      </div>
      </div>
  );
};

export default ContactOwner;
