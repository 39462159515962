import { Attendee, EventData } from "../../../type/type";

const useTransformEventsResponse = (apiResponse: EventData) => {

    const { items }: any = apiResponse;
    const eventType=  items.map((item: any) => {
      return  transformEvents(item)
    });
    return eventType
};

// New function to handle the transformation
export const transformEvents = (item: any) => {
        const startDateTime = item.start.date_time;
        const endDateTime = item.end.date_time;
        return {
            id: item.id,
            summary: item.summary,
            description: item.description,
            location: item.location,
            attendees: item.attendees.map((attendee: Attendee) => ({
                email: attendee.email,
                displayName: attendee.displayName || attendee.email,
                responseStatus: attendee.responseStatus,
            })),
            conferenceData: item.conference_data,
            dateTimeRange: [startDateTime, endDateTime],
            created: item.created,
            updated: item.updated,
            creator: item.creator.email,
            color_id: item.color_id,
            event_color: item.event_color
        };

};

export default useTransformEventsResponse;