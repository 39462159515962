import { Image, Typography } from "antd";
import React, { useEffect, useState } from "react";
import SumitM from "../../images/SumitM.png";
import NupurD from "../../images/NupurD.png";
import MichaelT from "../../images/MichaelT.png";
const {Text} = Typography;
const TestimonialCarousel = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const testimonials = [
    {
      name: "Sumit M",
      role: "Co-Founder (CTO and CHRO)",
      company: "NonStop io",
      image: SumitM,
      quote:
        "What I like best about Tacitbase is how it makes hiring so much easier and faster for me and my team. The automatic resume ranking is a huge time-saver, we don't have to spend hours going through resumes manually anymore. One of my favorite features is the Slack integration.",
    },
    {
      name: "Nupur D",
      role: "Chief of staff",
      company: "Intellicar",
      image: NupurD,
      quote:
        "As a client, I'm incredibly satisfied with Tacitbase. It empowers our recruiting partner to handle high volumes of applications efficiently, streamlining the entire process for us. The platform's ability to support complex workflows ensures that our specific needs are met.",
    },
    {
      name: "Michael T",
      role: "Lead Admin",
      company: "SARAL",
      image: MichaelT,
      quote:
        "Even for non-techies, the platform is very user-friendly and provides a wide range of features, such as interview scheduling, job boards, and applicant tracking. It saves us a lot of time and money by automating a lot of manual tasks, which I also adore.",
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="w-full flex flex-col">
      <div className="flex-grow flex items-center">
        <div className="w-full relative overflow-hidden">
          <div
            className="w-full flex transition-transform duration-1000 ease-in-out"
            style={{
              transform: `translateX(-${currentTestimonial * 100}%)`,
            }}
          >
            {testimonials.map((testimonial, index) => (
              <div key={index} className="w-full flex-shrink-0 h-full ">
                <div className="p-6 rounded bg-white backdrop-blur-sm " >
                  <div className="flex items-center mb-4">
                    <div className="flex">
                      <div className="mr-4">
                        <Image
                          src={testimonial.image}
                          width={80}
                          alt={testimonial.name}
                          className="object-cover rounded-full border flex justify-center items-center"
                          preview={false}
                        />
                      </div>
                      <div className="flex-col">
                        <div className="pb-2">
                          <Text className="font-light">
                          &quot;{testimonial.quote}&quot;
                          </Text>
                        </div>
                        <div className="flex justify-between">
                          <div>
                            {testimonial.name}, {testimonial.role}
                          </div>
                          <div>{testimonial.company}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-8 space-x-4">
        {testimonials.map((_, index) => (
          <button
            key={index}
            className={`h-1 rounded-full transition-all duration-300 ${
              index === currentTestimonial
                ? "w-8 bg-gradient-to-r from-tacitbase-secondary3 to-tacitbase-primary2"
                : "w-2 bg-purple-200"
            }`}
            onClick={() => setCurrentTestimonial(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default TestimonialCarousel;
