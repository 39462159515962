// import { UserOutlined } from "@ant-design/icons";
import {  Button, Divider, Form, Input, Result, Typography } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router";
import BrandingPanel from "./BrandingPanel";
const { Text } = Typography;
import { getUser, registration, resendEmailVerification } from "../../lib/api";
import { setTokenInLocalStorage } from "../../lib/api";
import { Dispatch } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loginUserAc } from "../../redux/actions/opening/action";
import TB_ORANGE_LOGO from "../../images/TB full orange.png";
import { REGISTRATION_TITLE } from "../../constant";
import { loginUser } from "../../type/type";
import { throwSuccess } from "../../service/throwError";

type SignUpFormProps = {
  loginUserAc(token: string, data: any): void;
  loginUser:loginUser;
} & RouteComponentProps;

class SignupPage extends React.Component<SignUpFormProps> {
  formRef = React.createRef<any>();
  coolDownInterval: any;
  state = {
    loading: false,
    errorMessage: "", // Add a state to store the error message
    isUserCreated: false,
    resendLoading: false,
    coolDown: 0, 
    isPageActive: true,
  };
  // variables for polling mechanism
   polling: number | undefined;
   activeTimer: number | undefined;
   pollingInterval: number = 3000;
   startTime: number = Date.now();
   nextPollTime: number | null = null;
   isTimeout: boolean = false;
  validatePassword = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a password!"));
    }
    if (value.length < 8) {
      return Promise.reject(
        new Error("Password must be at least 8 characters")
      );
    }
    if (/\s/.test(value)) {
      return Promise.reject(new Error("Password cannot contain spaces"));
    }
    return Promise.resolve();
  };
  componentDidMount = async () => {
    document.title = `${REGISTRATION_TITLE}`
    
    // resetting the cool down interval if user refresh the page
    if (this.coolDownInterval) {
      clearInterval(this.coolDownInterval);
    }

    // checking like if user is having a login token
    const token = await localStorage.getItem("login_token");
    try {
      if (!token) {
        return;
      }
      if (!this.props.loginUser?.user?.email) {
        localStorage.removeItem("login_token");
        return;
      }
      if (this.props.loginUser.user.verified) {
        this.props.history.push('/onboarding');
        return;
      }
      if (!this.props.loginUser.user.verified) {
        this.setState({ isUserCreated: true });
      }
    } catch (error) {
      localStorage.removeItem('login_token');
    }

       // implementing pooling mechanism to automatically redirect user based on user current state
      this.clearAllIntervals();
      // Set up visibility change listener
      document.addEventListener('visibilitychange', this.handleVisibilityChange);

      // Start polling if page is active
      if (document.visibilityState === 'visible' && this.state.isUserCreated) {
        this.startTime = Date.now();
        this.startPolling();
        this.trackActiveTime();
      }
  };
  componentWillUnmount() {
    this.cleanup();
  }
   pollServer = async () => {
    if (document.hidden || !this.state.isUserCreated) {
      return;
    }
    try {
      const userRes = await getUser(this.props?.loginUser?.user?.id);
      
      if (userRes?.verified) {
        this.stopPolling();
        window.location.href = '/onboarding';
      } else {
        this.stopPolling();
        this.pollingInterval *= 2;
        this.startPolling();
      }
    } catch (error) {
      console.error('Error checking email verification:', error);
      this.stopPolling(); 
    }
  }
   startPolling = () => {
    if (this.isTimeout || document.hidden || !this.state.isUserCreated) return;
    this.stopPolling();
    this.nextPollTime = Date.now() + this.pollingInterval;
    this.polling = window.setInterval(this.pollServer, this.pollingInterval);
  }

   stopPolling = () => {
    if (this.polling) {
      clearInterval(this.polling);
      this.polling = undefined;
      this.nextPollTime = null;
    }
  }
   resetAll = () => {
    this.stopPolling();
    if (this.activeTimer) {
      clearInterval(this.activeTimer);
    }
    this.pollingInterval = 3000;
    this.startTime = Date.now();
    this.isTimeout = false;
  }
   handleVisibilityChange = () => {
    if (document.hidden) {
      this.setState({ isPageActive: false });
      this.stopPolling();
      this.resetAll();
      if (this.activeTimer) {
        clearInterval(this.activeTimer);
      }
    } else {
      if (!this.isTimeout && this.state.isUserCreated) {
        this.setState({ isPageActive: true });
        this.pollServer(); 
        this.startTime = Date.now();
        this.pollingInterval = 3000; 
        this.startPolling();
        this.trackActiveTime();
      }
    }
  }
   trackActiveTime = () => {
    if (this.activeTimer) {
      clearInterval(this.activeTimer);
    }

    this.activeTimer = window.setInterval(() => {
      const currentTime = Date.now();
      const elapsedSeconds = Math.floor((currentTime - this.startTime) / 1000);
      
      if (elapsedSeconds >= 200) {
        this.stopPolling();
        this.isTimeout = true;
        clearInterval(this.activeTimer);
      }
    }, 1000);
  }
   clearAllIntervals = () => {
    if (this.coolDownInterval) {
      clearInterval(this.coolDownInterval);
    }
    this.stopPolling();
    if (this.activeTimer) {
      clearInterval(this.activeTimer);
    }
  }
   cleanup = () => {
    this.resetAll();
    this.clearAllIntervals();
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }
  setCoolDown = (seconds: number) => {
    this.setState({ coolDown: seconds });
    this.coolDownInterval = setInterval(() => {
      this.setState(
        (prevState:any) => ({
          coolDown: Math.max(prevState.coolDown - 1, 0),
        }),
        () => {
          if (this.state.coolDown === 0 && this.coolDownInterval) {
            clearInterval(this.coolDownInterval);
          }
        }
      );
    }, 1000);
  };
   resendEmail = () => {
    if (this.state.coolDown > 0) return; 
      this.setState({ resendLoading: true });
      resendEmailVerification(this.props.loginUser.user.email)
        .then((res: any) => {
          if (res) {
            this.setCoolDown(30);
            this.setState({ resendLoading: false });
            throwSuccess("Email has been sent successfully");
          }
        })
        .catch(() => {
          this.setState({ resendLoading: false });
        })
    }

  onFinish = (values: any) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ loading: true });
    let userValues = {
      ...values,
      timezone: userTimezone,
    };
    registration({
      user: userValues,
    })
      .then((res) => {
          setTokenInLocalStorage("login_token", res.token).then((flag: any) => {
            if (flag && res.token) {
              this.props.loginUserAc(res.token, res.user);
  
              if (!res.user.verified) {
                this.setState({ 
                  isUserCreated: true,
                  loading: false 
                });
                if (document.visibilityState === 'visible') {
                  this.startTime = Date.now();
                  this.startPolling();
                  this.trackActiveTime();
                }
                // this.props.history.push("/onboarding");
              }
            }
            this.setState({ loading: false });
          });
        
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          this.formRef.current.setFields([
            {
              name: "email",
              errors: ["An account already exist with this email address"],
            },
          ]);
          this.setState({ loading: false });
        } else if (error.response && error.response.status === 422) {
          this.formRef.current.setFields([
            {
              name: "email",
              errors: [error.response?.data?.error?.message],
            },
          ]);
          this.setState({ loading: false });
        } else if (error.response && error.response.status === 400) {
          this.formRef.current.setFields([
            {
              name: "password",
              errors: [error.response?.data?.error?.message],
            },
          ]);
          this.setState({ loading: false });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  render() {
    if (this.state.isUserCreated) {
      return (
        <div className="flex min-h-screen max-h-screen justify-center items-center overflow-hidden">
          <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
            <div className="w-full sm:w-full md:w-full lg:w-10/12 xl:9/12 2xl:w-8/12">
              <Result
                status="success"
                title={
                  <span className="text-sm" style={{ color: "#009D79" }}>
                    Account Created Successfully!
                  </span>
                }
                subTitle={
                  <div className="flex flex-col gap-1">
                    <span>
                      We&apos;ve sent a verification link to your email address.
                    </span>
                    <span>
                      Haven&apos;t received the email? Check your spam folder
                    </span>
                    <span>or</span>
                    <Button
                      disabled={this.state.coolDown > 0}
                      type="link"
                      onClick={this.resendEmail}
                      loading={this.state.resendLoading}
                      className="p-0 mx-1"
                    >
                      {this.state.coolDown > 0
                        ? `Resend in ${this.state.coolDown}s`
                        : "Resend verification email"}
                    </Button>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="flex min-h-screen max-h-screen overflow-hidden ">
        {/* Left Panel */}
        <BrandingPanel />
        {/* Right Panel - Sign Up Form */}
        <div className="w-full lg:w-1/2 flex lg:items-center lg:justify-center p-8 ">
          <div className="w-full sm:w-full md:w-full lg:w-10/12 xl:9/12 2xl:w-8/12 space-y-8">
            <div className="lg:hidden flex  mb-8">
              <img
                src={TB_ORANGE_LOGO}
                alt="Tacitbase Logo"
                className="w-48"
                style={{ backgroundColor: "transparent" }}
              />
            </div>
            <div className="text-left flex flex-col gap-2">
            <Text className="lg:text-2xl text-xl  font-bold bg-gradient-to-r text-tacitbase-secondary3">
                Sign up{" "}
              </Text>
              <Text className="text-gray-600 text-xs md:text-sm">
                14-day free trial • No credit card needed
              </Text>
            </div>
            <Form
              requiredMark={false}
              name="normal_login"
              className="login-form text-xs w-full"
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              layout="vertical"
              ref={this.formRef}
            >
              <Form.Item
                name="email"
                label="Work email"
                rules={[
                  { required: true, message: "Please input your Work Email!" },
                  { type: "email", message: "Invalid email format" },
                ]}
                // className=" font-medium"
              >
                <Input size="large" placeholder="johndoe@yourcompany.com" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                // className=" font-medium"
                rules={[{ required: true, validator: this.validatePassword }]}
              >
                <Input.Password size="large" />
              </Form.Item>
              <Form.Item shouldUpdate style={{ marginTop: "20px" }}>
                {/* {(form) => {
                  const hasErrors = form
                    .getFieldsError()
                    .some(({ errors }) => errors.length);
                  const hasValues = form.getFieldsValue();
                  const isFieldsFilled = hasValues.email && hasValues.password;
                  return ( */}
                <Button
                  size="large"
                  // disabled={hasErrors || !isFieldsFilled}
                  loading={this.state.loading}
                  htmlType="submit"
                  type="primary"
                  className="w-full mb-3"
                >
                  Create Account
                </Button>
              </Form.Item>
              <Text className="text-gray-600 text-xs md:text-sm">
                By signing up, you agree to our&nbsp;
                <a
                  href="https://www.tacitbase.com/terms-of-use"
                  style={{ color: "#009D79" }}
                >
                  terms of use{" "}
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://www.tacitbase.com/privacy"
                  style={{ color: "#009D79" }}
                >
                  {" "}
                  privacy policy.
                </a><br />
                <Text className="text-gray-600 text-xs md:text-sm">
                  Need help? Visit&nbsp;
                  <a
                    href="https://support.tacitbase.com/"
                    style={{ color: "#009D79" }}
                  >
                    support.
                  </a>
                </Text>
              </Text>
              <Divider />
              <div className=" mt-3 text-gray-600">
                <Text className=" text-gray-600">Already have an account?</Text>{" "}
                <Button
                  type="link"
                  className="p-0"
                  onClick={() => this.props.history.push("/login")}
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginUserAc: (token: string, data: any) => dispatch(loginUserAc(token, data)),
});
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignupPage));
