import React, { lazy, useEffect, useState } from "react"
import { getCompanies, getJDDataBoardWise, getOpenings } from "../../lib/api"
import { connect } from "react-redux"
import {
  companyMember,
  initCompanies,
  initServiceEntityNotificationsDot,
  listOfBoards,
  setViewType,
  storedJdData,
  sortingBoard,
} from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
const PageContentSectionName = lazy(() => import("./sectionName/PageContentSectionName"))
const PageContentSectionTableView = lazy(() => import("./sectionName/PageContentSectionTableView"))
import Sorting from "../common/sorting/Sort"
import { } from "../../lib/api"
import {
  JobBoardSummary,
  Member,
  Opening,
  company,
  loginUser,
  notificationServiceEntityDotChangePayloadType,
  orgMember,
} from "../../type/type"
import BoardLoader from "./boardPreview/LoaderOfBoard"
import { ASCENDING, colors, DIVIDER_HEIGHT, FETCHING_BOARDS,PlansName } from "../../constant"
import SearchBox from "./jobPostHeader/JobPostSearchBox"
import Navbar from "../navbar/Navbar"
import { Breadcrumb, Divider, Segmented,  Tooltip } from "antd"
import ContentHeader from "../analyticsVersion1/ContentHeader"
import JobPostActionButton from "./jobPostHeader/JobPostActionButton"
import { IconButton } from "../../ant/Button"
import { AppstoreOutlined, ReloadOutlined, TableOutlined } from "@ant-design/icons"
import SuspenseLoader from "../SuspenseLoader"
import OnboardingModal from "../../pages/setting/billingSetting/OnboardingModal"
import { newOrganisation } from "../../lib/apiRes"

export type HomepageProps = {
  listOfBoards(boardData: any): void,
  applicationId: string,
  loginUser: loginUser,
  initBoardNotificationDot(
    payload: notificationServiceEntityDotChangePayloadType
  ): void,
  initCompanies: (JobBoard: company[]) => void,
  initCompanyMembers: (members: Member[]) => void,
  members: orgMember,
  setViewType: (viewType: string) =>void,
  viewType:any;
  setJdData(jdData: JobBoardSummary[]): void,
  sortingBoard: (selectionType: any) => void,
  currentOrg: newOrganisation;
}

const Homepage = (props: HomepageProps) => {
  // const params: { org_id: string } = useParams();
  const [loading, setLoading] = useState<boolean>(false)
  const [sortValue, setSortValue] = useState("")
  const [searchdKeyword, setSearchedValue] = useState("")
  const [refresh, setRefresh] = useState<boolean>(false); // Refresh state
  const [isGridView, setIsGridView] = useState(props.viewType==="Grid"|| !props.viewType );
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const isNewUser = localStorage.getItem("newUser");
    if (isNewUser === "newUser" && props.currentOrg.customer?.metadata?.plan.toLowerCase() === PlansName.free_trail) {
      setIsModalVisible(true);
    }
    setLoading(true)
    Promise.all([
      getCompanies(),
      getOpenings()
    ]).then(([companies, openings]) => {
      props.initCompanies(companies)
      props.listOfBoards(openings)
      props.sortingBoard(ASCENDING);
      setLoading(false)
    })
    getJDDataBoardWise().then((jdData:JobBoardSummary[])=>{
      props.setJdData(jdData)
    })
    return () => {
      setLoading(false)
      setSortValue("")
      setSearchedValue("")
    }
  }, [refresh])
  const get = (value: any) => {
    setSortValue(value)
  }
  const getSearchKeyword = (e: string) => {
    setSearchedValue(e)
  }

  const refreshData = () => {
    setSortValue("") // Clear the sort value
    setSearchedValue("") // Clear the searched keyword
    setRefresh(!refresh); // Toggle refresh state
  };

   // Toggle between grid and table view
   const handleViewChange = (value: string) => {
    const selectedView = value === "Grid" ? "Grid" : "Table";
    setIsGridView(selectedView === "Grid");
    props.setViewType(selectedView); 

  };

  const handleCloseModal = () => {
    localStorage.removeItem("newUser");
    setIsModalVisible(false);
  };

  return (
    <React.Fragment>
      <OnboardingModal
      visible={isModalVisible}
      onClose={handleCloseModal} />
      <div
        className="flex flex-col" >
        <ContentHeader>

          <div
            className="flex flex-row items-center px-4  py-3 justify-between"
            style={{ height: "48px" }}
          >
            <div className="flex items-center">
              <Breadcrumb
                items={[
                  {
                    title: 'Job posts',
                  }
                ]}
              />  </div>
            <div className="flex flex-row items-center">
              <Navbar />

            </div>          </div>
        </ContentHeader>



        <div className="flex py-2 px-4 bg-white border-b justify-between items-center">
          <div className="flex flex-row items-center">
            <JobPostActionButton />
          </div>
          <div className="flex-row space-x-2 flex justify-end items-center">
          {/* <Tooltip title={isGridView ? "Switch to Table View" : "Switch to Grid View"}> */}
          <Segmented
                options={[
                  {
                    label: (
                      <Tooltip title={"Switch to table view"}>
                        <span>
                          <TableOutlined />
                        </span>
                      </Tooltip>
                    ),
                    value: 'Table',
                  },
                  {
                    label: (
                      <Tooltip title="Switch to grid view" >
                        <span>
                          <AppstoreOutlined />
                        </span>
                      </Tooltip>
                    ),
                    value: 'Grid',
                  },
                ]}
                value={isGridView ? 'Grid' : 'Table'}
                onChange={handleViewChange}
                // style={{backgroundColor:'#FBFAFD'}}
              />
      {/* </Tooltip> */}
            <SearchBox onSearchChange={getSearchKeyword} />
            <Sorting c={(value: any) => get(value)} />
            <Divider
              type="vertical"
              style={{
                height: DIVIDER_HEIGHT,
                borderColor: `${colors.divider}`,
              }}
            />

            <IconButton
              tooltipTitle="Refresh tab"
              icon={
                <ReloadOutlined />
              }
              onClick={refreshData}
            />
          </div>
        </div>
      </div>
      <SuspenseLoader>

      {loading ? (
        <div className="overflow-y-scroll h-full bg-white rounded pl-4">
          <BoardLoader active={true} text={FETCHING_BOARDS} />
        </div>
      ) : isGridView ? (
        <PageContentSectionName
          sortValue={sortValue}
          searchedValue={searchdKeyword}
        />
      ) : (
        // If you have a List View component, you can render it here
        <PageContentSectionTableView
          sortValue={sortValue}
          searchedValue={searchdKeyword}
        />
      )}
      </SuspenseLoader>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  loginUser: state.opening.loginUser,
  members: state.opening.members,
  viewType: state.opening.viewType,
  currentOrg: state.opening.currentOrg,
})
const mapPropsTostate = (dispatch: Dispatch) => ({
  listOfBoards: (boardData: Opening[]) => dispatch(listOfBoards(boardData)),
  initBoardNotificationDot: (
    payload: notificationServiceEntityDotChangePayloadType
  ) => dispatch(initServiceEntityNotificationsDot(payload)),
  initCompanies: (candidates: company[]) =>
    dispatch(initCompanies(candidates)),
  initCompanyMembers: (members: Member[]) => dispatch(companyMember(members)),
  setViewType: (viewType: string) => dispatch(setViewType(viewType)),
  setJdData: (jdData: JobBoardSummary[]) => dispatch(storedJdData(jdData)),
  sortingBoard: (selectionType: string) =>
      dispatch(sortingBoard(selectionType)),
})

export default connect(mapStateToProps, mapPropsTostate)(Homepage)
