import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import { feedback, loginUser, orgMember } from "../../type/type.d";
import {
  addFeedback,
  initFeedback,
  updateFeedback,
} from "../../redux/actions/opening/action";
import { Divider, Empty, Typography } from "antd";
import parse from "html-react-parser";
import { createFeedback, editFeedback, getFeedbacks } from "../../lib/api";
import { DATE_TIME_FORMATE } from "../../constant";
import TacitbaseAvtar from "../../ant/Avtar";
import moment from "moment";
import Tag from "antd/lib/tag";

import Editor from "../../ant/Editor/Editor";
import { LabeledIconButton } from "../../ant/Button";
import { SendOutlined } from "@ant-design/icons";
import { AddFeedbackReq, EditFeedbackReq } from "../../lib/apiReq";
import { UnprivilegedEditor } from "react-quill";
import { useParams } from "react-router-dom";
const { Text } = Typography;
const DetailedReviewContent = (props: {
  updateFeedback(feedback: feedback): void;
  members: orgMember;
  initFeedback: (feedback: feedback[], selectedMeetingId: string) => void;
  loginUser: loginUser;
  addFeedback: (feedback: feedback) => void;
  feedback: any;
}) => {
  const MAX_COMMENT_LENGTH = 2000;

  const [editingCommentId, setEditingCommentId] = useState<string>("");
  const [editingCommentTag, setEditingCommentTag] = useState<string>("");
  const [editedCommentBody, setEditedCommentBody] = useState("");
  const [newComment, setnewComment] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [commentValidationMessage, setCommentValidationMessage] =
    useState<string>("");
  const [isFocused, setIsFocused] = useState(false);

  const handleEdit = (commentId: string, body: string, tag: string) => {
    setEditingCommentId(commentId);
    setEditedCommentBody(body);
    setEditingCommentTag(tag);
  };
  const handleEditedComment = (content: string) => {
    setEditedCommentBody(content);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  // Handle blur event
  const handleBlur = () => {
    setIsFocused(false);
  };

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [props.feedback]);

  const saveComment = () => {
    if (!newComment) return;
    if (commentValidationMessage.length > 0) return;
    setShowLoading(true);
    let commentReq: AddFeedbackReq = {
      feedback_description: newComment.trim(),
      meeting_id: params.meeting_id,
      feedback_option: selectedOption,
    };
    createFeedback(commentReq)
      .then((res) => {
        setShowLoading(false);
        setSelectedOption("");
        props.addFeedback(res); // Dispatch addFeedback action
        setnewComment("");
        setEditingCommentId("");
        setEditedCommentBody("");
      })
      .catch(() => {
        setShowLoading(false);
      });
    // }
  };
  const handleKeyDownComment = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13 && e.metaKey) {
      // Save the comment when Command + Enter is pressed
      saveComment();
    }
  };
  const handleKeyDownEditedComment = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.keyCode === 13 && e.metaKey) {
      // Save the comment when Command + Enter is pressed
      updateReviewComment();
    }
  };

  const updateReviewComment = () => {
    const postComment: EditFeedbackReq = {
      feedback_description: editedCommentBody.trim(),
      id: editingCommentId,
      feedback_option: editingCommentTag,
    };
    editFeedback(postComment).then((comment) => {
      if (comment) {
        props.updateFeedback(comment);
        setEditingCommentId("");
        setSelectedOption("");
        setEditedCommentBody("");
      }
    });
  };

  const customModules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      // ['clean']
    ],
  };

  const customFormats = ["bold", "italic", "underline", "list", "bullet"];
  const renderComments = () => {
    const sortedComments = props?.feedback?.[params.meeting_id] || [];

    if (sortedComments.length === 0) {
      return (
        <div className="flex h-full w-full justify-center items-center">
          <Empty
            description="No feedbacks yet"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </div>
      );
    }

    return sortedComments.map((comment: any) => {
      const isEdited =
        new Date(comment.created_at) < new Date(comment.updated_at);
      return (
        <div key={comment.id}>
          <div
            className={`flex ${
              comment.user_id === props.loginUser.user.id
                ? "justify-end"
                : "justify-start"
            } mb-2`}
          >
            <div
              className={`flex flex-col space-y-1 ${
                comment.user_id === props.loginUser.user.id
                  ? "justify-end"
                  : "justify-start"
              }`}
            >
              <div
                className={`flex ${
                  comment.user_id === props.loginUser.user.id
                    ? "justify-end"
                    : "justify-start"
                } items-center space-x-2`}
              >
                {comment.user_id === props.loginUser.user.id ? (
                  // Display for "You"
                  <>
                    <Text className="font-normal text-xs">
                      {moment(comment.created_at).format(DATE_TIME_FORMATE)}
                    </Text>
                    <Text strong className="font-normal">
                      You
                    </Text>
                    <TacitbaseAvtar
                      size="default"
                      src={
                        props.members[comment.user_id]?.image_url || undefined
                      }
                      content={
                        !props.members[comment.user_id]?.image_url
                          ? props.members[
                              comment.user_id
                            ]?.first_name?.[0]?.toUpperCase() || ""
                          : undefined
                      }
                    />
                  </>
                ) : (
                  // Display for other users
                  <>
                    <TacitbaseAvtar
                      size="default"
                      src={
                        props.members[comment.user_id]?.image_url || undefined
                      }
                      content={
                        !props.members[comment.user_id]?.image_url
                          ? props.members[
                              comment.user_id
                            ]?.first_name?.[0]?.toUpperCase() || ""
                          : undefined
                      }
                    />
                    <Text strong className="font-normal">
                      {props.members[comment.user_id]?.first_name}
                    </Text>
                    <Text className="font-normal text-xs">
                      {moment(comment.created_at).format(DATE_TIME_FORMATE)}
                    </Text>
                  </>
                )}
              </div>

              {editingCommentId === comment.id ? (
                <>
                  <div
                    style={{
                      boxShadow:
                        "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                    }}
                    className="comment-editor border rounded w-100 mr-8 "
                  >
                    <Editor
                      modules={customModules}
                      onKeyDown={handleKeyDownEditedComment}
                      formats={customFormats}
                      defaultValue={editedCommentBody}
                      value={editedCommentBody}
                      onChange={handleEditedComment}
                    />
                    <div className="flex flex-wrap justify-end py-2 px-3 space-x-2">
                      <div className="flex space-x-2">
                        {options.map((option) => (
                          <Tag
                            key={option}
                            color={
                              editingCommentTag === option
                                ? "purple"
                                : "default"
                            }
                            onClick={() => setEditingCommentTag(option)}
                            className="cursor-pointer"
                          >
                            {option}
                          </Tag>
                        ))}{" "}
                      </div>
                      <LabeledIconButton
                        size="small"
                        label=""
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={updateReviewComment}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="rounded px-2 py-1"
                    style={{
                      background:
                        comment.user_id === props.loginUser.user.id
                          ? "#F6F4FF66"
                          : "#EAFFF666",
                    }}
                  >
                    {parse(comment.feedback_description)}
                  </div>
                  {comment.feedback_option &&
                    comment.feedback_option.length > 0 && (
                      <div className="flex mt-1 justify-end">
                        <Tag color="purple">{comment.feedback_option}</Tag>
                      </div>
                    )}
                </>
              )}
              {isEdited && (
                <Text type="secondary" style={{ fontSize: "12px" }}>
                  {"(edited)"}
                </Text>
              )}
              {comment.user_id === props.loginUser.user.id && (
                <Tag
                  bordered={false}
                  onClick={() =>
                    handleEdit(
                      comment.id,
                      comment.feedback_description,
                      comment.feedback_option
                    )
                  }
                  className="cursor-pointer w-9"
                >
                  {"edit"}
                </Tag>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const handleEditorChange = (
    content: string,
    delta: any,
    source: string,
    editor: UnprivilegedEditor
  ) => {
    if (editor.getText().length > 0) {
      setnewComment(content);

      if (content.trim().length > MAX_COMMENT_LENGTH) {
        // If it exceeds, set the validation message
        setCommentValidationMessage("Your feedback is too long");
      } else {
        // If it's within the limit, clear the validation message
        setCommentValidationMessage("");
      }
    }
  };
  const params: { org_id: string; meeting_id: string } = useParams();

  useEffect(() => {
    if (params.meeting_id) {
      getFeedbacks(params.meeting_id)
        .then((res) => {
          props.initFeedback(res, params.meeting_id); // Initialize feedback in Redux
        })
        .finally(() => {});
    }
  }, [params.meeting_id]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const options = ["Strong Yes", "Strong No", "Weak Yes", "Weak No"];
  const hasCommentedByUser = 
  Array.isArray(props.feedback?.[params.meeting_id]) && 
  props.feedback[params.meeting_id].some(
    (comment: any) => comment?.user_id === props.loginUser?.user?.id
  ) || false;

  return (
    <>
      <div className="flex flex-col h-full justify-between items-baseline w-full comment-editor">
        <div
          style={{
            height: !hasCommentedByUser
              ? "calc(100vh - 420px)"
              : "calc(100vh - 300px)",
            minHeight: "100px",
          }}
          className=" py-2 w-full "
        >
          <div className="my-2 pr-2 h-full overflow-y-scroll ">
            {renderComments()}
            <div ref={messagesEndRef}></div>
          </div>
        </div>
        {!hasCommentedByUser && (
          <div className="w-full comment-editor sticky bottom-0  bg-white">
            <Divider className="mb-3  mt-2 bg-white" />
            <div
              className={` rounded ${
                isFocused ? "quill-editor-focused" : "border"
              }`}
              style={{
                boxShadow:
                  "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
              }}
            >
              <Editor
                onFocus={handleFocus}
                modules={customModules}
                onKeyDown={handleKeyDownComment}
                formats={customFormats}
                defaultValue={newComment}
                value={newComment}
                initialValue={newComment}
                onChange={handleEditorChange}
                onBlur={handleBlur}
              />
              <div></div>
              <div className="flex w-full py-2 pl-4 pr-2">
                {/* <EmojiPicker onEmojiSelect={handleEmojiSelect} /> */}
                <div className="justify-start flex w-full text-red-600">
                  {commentValidationMessage}
                </div>
                <div className="flex justify-end space-x-2">
                  <div className="flex space-x-2">
                    {options.map((option) => (
                      <Tag
                        key={option}
                        color={selectedOption === option ? "purple" : "default"}
                        onClick={() => setSelectedOption(option)}
                        className="cursor-pointer"
                      >
                        {option}
                      </Tag>
                    ))}
                  </div>
                  <LabeledIconButton
                    size="small"
                    label=""
                    loading={showLoading}
                    type="primary"
                    icon={<SendOutlined />}
                    onClick={saveComment}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  members: state.opening.members,
  loginUser: state.opening.loginUser,
  feedback: state.opening.feedback,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initFeedback: (feedback: feedback[], selectedMeetingId: string) =>
    dispatch(initFeedback(feedback, selectedMeetingId)),
  addFeedback: (feedback: feedback) => dispatch(addFeedback(feedback)),
  updateFeedback: (feedback: any) => dispatch(updateFeedback(feedback)),
});
export default connect(mapStateToProps, mapPropsToState)(DetailedReviewContent);
