import React from "react";
import { Avatar,Typography, Tooltip } from "antd";
import { ExportOutlined } from "@ant-design/icons";
const {Text} = Typography;
const JobBoardCard = (props:{ bgColor:string}) => {
  return (
    <div className="border border-gray-300 rounded-lg shadow-md" style={{width:"280px"}}>
      <div className={`h-20 text-white p-2 rounded-t-lg`}
      style={{backgroundColor:props.bgColor,opacity:"0.3"}}>
        <Text className="text-lg text-white font-semibold">Java developer intern</Text>
      </div>
      <div className="flex items-center justify-between p-2 bg-white rounded-b-lg">
        <div className="flex items-center space-x-2">
          <Tooltip title="User 1">
            <Avatar />
          </Tooltip>
          <Tooltip title="User 2">
            <Avatar />
          </Tooltip>
        </div>
        <Tooltip title="Open">
          <button className="p-2 rounded-full hover:bg-gray-100">
            <ExportOutlined className="text-gray-600" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default JobBoardCard;
