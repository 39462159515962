import React from "react";
import { Button, Card, Typography, Space } from "antd";
import { PlusOutlined, TeamOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const NoOrganizations = () => {
  const history = useHistory();

  const handleCreateOrg = (e: any) => {
    e.preventDefault();
    history.push("/create-org");
  };

  return (
    <div className="px-6 py-4">
      <Card>
        <div className="flex flex-col items-center text-center py-8">

          <p className="text-lg lg:text-xl text-gray-700 font-medium mb-4">
            Start Your First Organization
          </p>

          <Text className="text-gray-500 max-w-md mb-8">
            Create an organization to collaborate with your team, manage
            projects, and streamline your workflow.
          </Text>

          <Space direction="vertical" size="large" className="w-full max-w-md">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleCreateOrg}
              className="min-w-[200px]"
            >
              Create Organization
            </Button>

            <div className="flex items-center justify-center gap-2 text-gray-500">
              <TeamOutlined />
              <Text type="secondary">
                Or wait for an invitation from your team
              </Text>
            </div>
          </Space>
        </div>
      </Card>
    </div>
  );
};

export default NoOrganizations;
