import { Typography, Progress, ConfigProvider } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LabeledIconButton } from "../../ant/Button";
import { loginUser, Opening, OrganizedBoard, orgMember, TotalBoards } from "../../type/type";
import { CrownOutlined } from "@ant-design/icons";
import { defaultTheme } from "../../themeConfig";
import { newOrganisation } from "../../lib/apiRes";
import GetplanModel from "../../pages/setting/billingSetting/GetplanModel";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { PlansName,TotalTrialDays } from "../../constant";

type subscriptionStatusCardProps = {
  currentOrg: newOrganisation;
  currentOpening: Opening;
  currentOrgJobPosts: OrganizedBoard;
  isCollaps: boolean;
  loginUser:loginUser;
  members: orgMember;
  totalBoards:TotalBoards;
};

const { Text } = Typography;

const SubscriptionStatusCard = (props: subscriptionStatusCardProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalCount,setTotalCount] = useState(props?.totalBoards?.total);
  const [activeCount,setActiveCount] = useState(props?.totalBoards?.total);
  const createdDate = new Date(props.currentOrg.created_at);
  const trialEndDate = new Date(createdDate);
  trialEndDate.setDate(trialEndDate.getDate() + TotalTrialDays);
  const today = new Date();
  const daysRemaining = Math.max(
    Math.ceil((trialEndDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)),
    0 
  );
  useEffect(()=>{ 
    setTotalCount(props?.totalBoards?.total);
    setActiveCount(props?.totalBoards?.active);
  },[props.totalBoards,props.currentOrg])

  // Calculate the progress percentage
  const progressPercent = (
    (activeCount / totalCount) *
    100
  ).toFixed(2);
  const handleUpgradeClick = () => {
      setIsModalVisible(true);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
    <div>
      {props.isCollaps ? (
        <div
          className="flex items-center justify-center"
          style={
            props.currentOpening.color
              ? {
                  width: "100%",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.25)",
                  padding: 4,
                }
              : {
                  width: "100%",
                  borderRightStyle: "solid",
                  borderRightWidth: "1px",
                  padding: 4,
                }
          }
        >
          <LabeledIconButton
            onClick={handleUpgradeClick}
            icon={<CrownOutlined />}
            type={props.currentOpening.color ? "default" : "primary"}
            label=""
            style={{ height: 28 }}
          />
        </div>
      ) : (
        <div
          className=""
          style={
            props.currentOpening.color
              ? {
                  width: "100%",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.25)",
                  padding: 4,
                }
              : {
                  width: "100%",
                  borderRightStyle: "solid",
                  borderRightWidth: "1px",
                  padding: 4,
                }
          }
        >
          <div
            className={`flex flex-col space-y-2 p-3 rounded ${
              props.currentOpening.color ? "bg-transparent" : "bg-white"
            }`}
            style={
              props.currentOpening.color
                ? {
                    border: "1px solid rgba(255, 255, 255, 0.25)",
                    color: "white",
                  }
                : {
                    borderLeftWidth: "1px",
                    borderLeftStyle: "solid",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "solid",
                    borderTopWidth: "1px",
                    borderTopStyle: "solid",
                    borderRightStyle: "solid",
                    borderRightWidth: "1px",
                  }
            }
          >
            <div className="flex space-x-2">
              <div
                className={`${
                  props.currentOpening.color ? "text-white" : "text-black"
                }`}
              >
                <Text
                  strong
                  className={`${
                    props.currentOpening.color ? "text-white" : "text-black"
                  }`}
                >
                  {props.currentOrg?.customer?.metadata?.plan?.toLowerCase() === PlansName.free_trail ? "Growth trial" :capitalizeFirstLetter(props.currentOrg?.customer?.metadata?.plan)}
                  {" "}plan
                </Text>
              </div>
            </div>

            {/* Display progress for job posts */}
            <div>
              <Text
                className={`${
                  props.currentOpening.color ? "text-white " : "text-black"
                }`}
              >
                {activeCount}/{totalCount} job posts created
              </Text>
              <ConfigProvider
                theme={{
                  token: {
                    /* here is your global tokens */
                    colorError: "#F1735D",
                    colorText: props.currentOpening.color ? "white" : "",
                  },
                }}
              >
                <Progress
                  trailColor={props.currentOpening.color ? "white" : ""}
                  strokeWidth={5}
                  strokeColor={
                    parseInt(progressPercent) == 100
                      ? "#F1735D"
                      : defaultTheme.colorPrimary
                  }
                  percent={parseInt(progressPercent)}
                  status={
                    parseInt(progressPercent) === 100 ? "exception" : "normal"
                  }
                />
              </ConfigProvider>
            </div>

            {props.currentOrg.customer?.metadata?.plan.toLowerCase() ==
            PlansName.free_trail ? (
              <div>
                <Text
                  className={`${
                    props.currentOpening.color ? "text-white" : "text-black"
                  } text-xs`}
                >
                 {daysRemaining > 0 ? (
                 `${daysRemaining} days left of your growth trial`
                    ) : (
                    `Your ${TotalTrialDays}-day growth trial has ended`
                  )}
                </Text>
              </div>
            ) : null}
            {props.currentOrg.customer?.metadata?.plan.toLowerCase() ==
            PlansName.free ? (
              <div>
                <Text
                  className={`${
                    props.currentOpening.color ? "text-white" : "text-black"
                  } text-xs`}
                >
                  Unlock more features and benefits with our Paid Plans!
                </Text>
              </div>
            ) : null}

            {props.currentOrg.customer?.metadata?.plan.toLowerCase() ==
            PlansName.premium ? (
              activeCount === totalCount ? (
                <>
                  <Text
                    className={`${
                      props.currentOpening.color ? "text-white" : "text-black"
                    } text-xs`}
                  >
                    You’ve reached the limit for your current premium plan. To
                    continue, please{" "}
                    <LabeledIconButton
                      className="text-xs"
                      style={{ padding: 0 }}
                      type="link"
                      label="Contact us"
                      href="mailto:support@tacitbase.com"
                    />{" "}
                  </Text>
                </>
              ) : null
            ) : (
              <>
                <LabeledIconButton
                  onClick={handleUpgradeClick}
                  icon={<CrownOutlined />}
                  type={props.currentOpening.color ? "default" : "primary"}
                  label="Upgrade now"
                  style={{ height: 28 }}
                />
              </>
            )}
          </div>
        </div>)}
        
    </div>
    {isModalVisible &&(<GetplanModel
    isModalVisible={isModalVisible}
    onCancel={handleModalCancel}
  />)}
  </>
  );
};

const mapStateToProps = (state: any) => ({
  currentOrg: state.opening.currentOrg,
  currentOrgJobPosts: state.opening.openings,
  currentOpening: state.opening.currentOpening,
  loginUser: state.opening.loginUser,
  members: state.opening.members,
  totalBoards:state.opening.totalBoards


});

export default connect(mapStateToProps)(SubscriptionStatusCard);
