import React, { useEffect, useLayoutEffect, useState } from "react";
import { ConfigProvider, Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import SideMenu from "./components/AppSideMenu/SideNav";
// import Navbar from "./components/navbar/Navbar";
// import FirstNavbar from "../src/components/newNavbar/Navbar";
import { connect } from "react-redux";
import { loginUser, Opening } from "./type/type";
const { Content } = Layout;
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import SettingMenuPopover from "./components/AppSideMenu/SettingMenuPopover"
import { useLocation } from "react-router-dom";
import { isMainProductURL, isOnBoardingURL, isPublicRoute } from "./utils/regExMatcher"
import {  BG_COLOR_SIDE_NAV, TACITBASE, titleMapping } from "./constant"
import TacitbaseLogoMenu from "./components/AppSideMenu/TacitbaseLogoMenu";
import SubscriptionStatusCard from "./components/AppSideMenu/SubscriptionStatusCard";
import NavSearch from "./components/navSearch/NavSearch";

const Tacitbase = (props: {
  currentOpening: Opening;
  children: React.ReactNode;
  applicationId: string;
  stripeCustomerStatus: any;
  loginUser:loginUser; 
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [ismainProductRoute, setIsmainProductRoute] = useState(false);
  const [isOnBoardingRoute, setIsOnboardingRoute] = useState(false);
  const [contentHeight, setContentHeight] = useState("auto");
  const location = useLocation();
  const [isPublic, setIsPublic] = useState(false);

  const hasMeetingsScope =
  props.loginUser.user.registrations?.[0]?.data?.scopes?.[0] === "meetings";

  // Effect hook to determine the route type based on the pathname
  useEffect(() => {
    const path = location.pathname
    const routeParts = path.split("/")
    const lastRoutePart = routeParts[routeParts.length - 1]
    const secondLastRoutePart = routeParts[routeParts.length - 2]
    if (secondLastRoutePart === "settings" && lastRoutePart) {
      const tabName = lastRoutePart.charAt(0).toUpperCase() + lastRoutePart.slice(1)
      document.title = `Settings | ${tabName}`
    } else if (titleMapping[lastRoutePart]) {
      const title = titleMapping[lastRoutePart] || lastRoutePart
      document.title = `${title} | ${TACITBASE}`
    }
    const isPublic = isPublicRoute(path)
    const isMainProduct = isMainProductURL(path)
    const isOnBoarding = isOnBoardingURL(path);
    if (isPublic) {
      setIsPublic(true)
      setIsOnboardingRoute(false)
      setIsmainProductRoute(false)

    }
    else if (isMainProduct) {
      setIsPublic(false)

      setIsmainProductRoute(true)
      setIsOnboardingRoute(false)
    } else if (isOnBoarding) {
      setIsPublic(false)

      setIsmainProductRoute(false)
      setIsOnboardingRoute(true)
    } else {
      setIsPublic(false)

      setIsmainProductRoute(false)
      setIsOnboardingRoute(false);
    }
  }, [location.pathname]);

  // Effect hook to calculate and update content height on window resize
  useLayoutEffect(() => {
    const calculateHeight = () => {
      const totalHeight = window.innerHeight;
      const headerHeight = 64; // replace with your actual header height
      const dynamicHeight = totalHeight - headerHeight;
      setContentHeight(`${dynamicHeight}px`);
    };

    // Initial calculation
    calculateHeight();

    // Update the height if the window is resized
    const handleResize = () => {
      calculateHeight();
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  
  // Render default layout if none of the specific routes match
  const renderDefaultLayout = () => {
    return (
      <Layout
        className={`h-screen flex fixed sm:w-full md:w-full w-full `}
        style={{ minHeight: "100vh" }}
      >
        <Content className="overflow-auto h-full bg-white">
          {props.children}
        </Content>
      </Layout>
    )
  }
  if (isPublic) {
    return (
      renderDefaultLayout()
    )
  }

  if (isOnBoardingRoute) {
    return (
      <Layout
        className={` h-screen flex fixed w-full `}
        style={{ minHeight: "100vh" }}
      >
        <Content
          className="overflow-auto"
          style={{ height: contentHeight }}
        >
          <div className="flex flex-col w-full  h-full ">
            {props.children}
          </div>
        </Content>
      </Layout>
    )
  }
  if (ismainProductRoute) {
    return (
      <ConfigProvider
        theme={
          props.currentOpening.color || props.currentOpening.background_image
            ? {
              components: {
                Layout: {
                  headerBg: "hsla(0,0%,0%,0.4)",
                  colorText: "white",
                  colorBgContainer: undefined,
                  siderBg: "hsla(0,0%,0%,0.4)",
                  triggerBg: "hsla(0,0%,100%,0.2)",
                  lightSiderBg: "hsla(0,0%,0%,0.4)",
                },
                Menu: {
                  colorText: "white",
                  colorBgContainer: undefined,
                  itemBg: `${`bg-color-primary2`}`,
                    itemHeight: 35,
                    itemActiveBg: "#F9F9FF",
                    itemSelectedBg: "#F0EFFE",
                    itemHoverBg: "rgba(0,0,0,0.06)",
                  },
                  Table: {
                    paddingXS: 6,
                    headerBg: "#fbfafd",
                    rowSelectedBg: "#ffffff",
                    rowSelectedHoverBg: "#fafafa",                  },
                },
              }
            : {
              components: {
                Layout: {
                  headerBg: "hsla(0,0%,0%,0.4)",
                  colorText: "black",
                  colorBgContainer: undefined,
                  
                  lightTriggerBg: BG_COLOR_SIDE_NAV,
                  lightSiderBg: BG_COLOR_SIDE_NAV,
                },
                Menu: {
                  colorBgContainer: undefined,
                  itemBg: BG_COLOR_SIDE_NAV,
                    itemHeight: 35,
                    itemActiveBg: "#F9F9FF",
                    itemSelectedBg: "#F0EFFE",
                    itemHoverBg: "rgba(0,0,0,0.04)",
                  },
                  Table: {
                    paddingXS: 6,
                    headerBg: "#fbfafd",
                    rowSelectedBg: "#ffffff",
                    rowSelectedHoverBg: "#fafafa",
                  },
                },
              }
        }
      >
        <Layout
          style={{
            background: props.currentOpening.background_image
              ? `url(${props.currentOpening.color}) center center / cover no-repeat`
              : "",
          }}
          className={`bg-color-${props.currentOpening.color} h-screen flex fixed w-full`}
        >
          {/* <FirstNavbar>
            <Navbar />
          </FirstNavbar> */}
          <Sider
            style={{
              borderRight: props.currentOpening.color || props.currentOpening.background_image
              ? "1px solid hsla(0,0%,100%,0.25)"
              : "1px solid white",
              borderRightWidth: "1px",
              borderRightStyle: "solid",
              left: 0,
              // marginTop: "52px",
              // backgroundColor:BG_COLOR_SIDE_NAV
            }}
            theme="light"
            // trigger={<div className={`${props.currentOpening.color || props.currentOpening.background_image?'': "border-r"} `} style={{marginRight:'1px'}}>{collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}</div>}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            trigger={null}
          >
            <div
              style={props.currentOpening.color||props.currentOpening.background_image?{                display: "flex",
                  borderBlockEndStyle: "solid",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: "100%",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.25)" ,

                }:
                {
                  borderBottomWidth: "1px", borderBottomStyle: "solid",               display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "100%",
                // backgroundColor:BG_COLOR_SIDE_NAV
                           }}
            >
              <TacitbaseLogoMenu isCollaps={collapsed}/>
              {hasMeetingsScope ? null : <NavSearch isCollaps={collapsed}/>}
              <SideMenu />
        {hasMeetingsScope ? null : (
              <><SubscriptionStatusCard isCollaps={collapsed}></SubscriptionStatusCard></>)}<SettingMenuPopover isCollaps={collapsed} />
              <div
        className={`flex justify-center py-2 cursor-pointer h-16 transition-[width] duration-300 ease-[cubic-bezier(0.2, 0, 0, 1)] ${
          props.currentOpening.color || props.currentOpening.background_image ? 'text-white' : "border-r"
        }`}
        style={ props.currentOpening.color || props.currentOpening.background_image
          ?{
              borderTop: "1px solid rgba(255, 255, 255, 0.25)" 
          }:
          
          {
              borderTopWidth: "1px",
              borderTopStyle: "solid",
      }}        onClick={() => setCollapsed(!collapsed)}
        tabIndex={-1}
        onKeyDown={()=>{}}
        role="button"
      >
        {collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
      </div>
            </div>
          </Sider>
          <Content
            className={`bg-color-${props.currentOpening.color} h-full`}
            style={{
              margin: 0,
              minHeight: 280,
              background: props.currentOpening.background_image
                ? `url(${props.currentOpening.color}) center center / cover no-repeat`
                : "",
              borderRadius: "4",
            }}
          >
            <div
              // style={{ paddingTop: "52px" }}
              className={` flex flex-col h-full text-black `}
            >
              <div className="flex flex-col overflow-x-hidden h-full ">
                {props.children}
              </div>
            </div>
          </Content>
        </Layout>
      </ConfigProvider>
    )
  }

  return renderDefaultLayout()
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  currentOpening: state.opening.currentOpening,
  stripeCustomerStatus: state.opening.billingCustomer.status,
  loginUser: state.opening.loginUser,
});

export default connect(mapStateToProps)(Tacitbase);
