// EventFormModal.tsx
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Tabs,
  List,
  Select,
  Dropdown,
  Menu,
  Typography,
  // Tag,
  Tooltip,
  Popover,
  Spin,
  Tag,
  Checkbox,
  Divider,
  Space,
  Modal,
  message,
  Switch,
  Alert,


} from "antd";
// import dayjs from "dayjs";
import TacitBaseModal from "../../../tacitbaseAnt/TacitbaseModal";
import Editor from "../../../ant/Editor/Editor";
import TimezoneSelect from "react-timezone-select";
import {
  EVENT_CREATION_SUCCESS_MSG,
  timezoneSelectCustomStyle,
  UserType,
} from "../../../constant";
import ResourceCalendar from "./ResourceCalender";
import { convertFreeBusyToEvents } from "./resourceGoogleResEvents";
import { connect, useDispatch, useSelector } from "react-redux";
import timezone from "dayjs/plugin/timezone";
import {
  Attachment,
  AttendeeWithImage,
  EventData,
  loginUser,
  Member,
  Scope,
} from "../../../type/type.d";
import FormItem from "../../../ant/FormItem";
import dayjs from "dayjs"; // Import dayjs for date manipulation
import {
  DownOutlined,
  CopyOutlined,
  CheckOutlined,
  WarningOutlined,
  SwapRightOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  LockOutlined,
} from "@ant-design/icons";
import Skype from "../../../assets/Skype";
import {
  createGoogleMeetLink,
  getConferneceDetails,
  getfreeBusySlot,
  getTrackerCardEventById,
  inviteMember,
  scheduleEvent,
  updateScheduledEvent,
  updateUrlAccess,
} from "../../../lib/api";
import { RootState } from "../../../redux/reducers/root";
import { useHistory, useParams } from "react-router-dom";
import { addOrgMember, editCardEvent, getActiveMeetings } from "../../../redux/actions/opening/action";
import {
  calendarListState,
  editEventInBoardCalendar,
  editEventInGlobalCalendar,
} from "../../../redux/reducers/calendarSlice";
import { getUserTimezone, processEvents } from "./calendarUtils";
import { Calendars } from "../../../lib/apiRes";
import { throwSuccess } from "../../../service/throwError";
import CardEventAttachments from "../../card/CardEvents/CardEventAttachments";
import MeetSvg from "../../../assets/MeetSVG";
// import MeetSvg from "../../../assets/MeetSVG";
import utc from "dayjs/plugin/utc";
import { Overlay } from "../../hook/Overlay";
import CloseIcon from "../../../assets/Close";
import CustomTimePicker from "./CustomTimePicker";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import  { transformEvents } from "../../card/CardEvents/useTransformEventsResponse";
import GuestItem from "./GuestItem";
import UpgradePlanModal from "../../../pages/setting/billingSetting/UpgradePlanModal";
import { Dispatch } from "redux";

dayjs.extend(utc);
dayjs.extend(timezone);

interface ConferenceDataOptions {
  googleMeet?: string;
  skypeOnlineMeeting?: boolean;
}
const { TabPane } = Tabs;
const { Text } = Typography;

const now = dayjs();
const currentMinute = now.minute();

// Determine the next 15-minute increment
const next15Min =
  currentMinute % 15 === 0 ? currentMinute : Math.ceil(currentMinute / 15) * 15;

if (next15Min === 60) {
  // If the next 15-minute increment is 60, move to the next hour and reset minutes to 0
  now.add(1, "hour").minute(0).second(0);
} else {
  // Otherwise, set the minutes to the next multiple of 15
  now.minute(next15Min).second(0);
}

const defaultStartTime = dayjs(now)
  .hour(now.hour())
  .minute(next15Min)
  .second(0);
  const defaultStartTime1 = dayjs(now)
  .hour(now.hour())
  .minute(next15Min)
  .second(0)
  .format('hh:mm A'); // 12-hour format with AM/PM

const defaultEndTime = dayjs(defaultStartTime)
  .add(30, "minutes")
  .hour(defaultStartTime.add(30, "minutes").hour())
  .minute(defaultStartTime.add(30, "minutes").minute())
  .second(0);

let defaultEventData: EventData = {
  summary: "",
  location: "Default Location",
  description: "",
  dateTimeRange: [defaultStartTime, defaultEndTime],
  visibility: "defaultVisibilityId", // Replace with your actual default visibility ID
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  reminderTime: 5, // 10 minutes before
  reminderType:"email",
  selectedColor: "blue", // Default color
  color_id: "",
  id: "",
  event_color: "",
  // attendees: []
};
interface EventFormModalProps {
  closeModal: () => void;
  totalMeetings:any;
  getActiveMeetings(data: any):void;
  addOutgoingInvitation(invitation: any): void;
}

const EventFormModal: React.FC<EventFormModalProps> = ({
  closeModal,
  totalMeetings,
  getActiveMeetings,
  addOutgoingInvitation,
  // onError,
}) => {
  const params: {
    event_id: string;
    card_id: string;
    org_id: string;
    board_id: string;
    calendar_id: string;
  } = useParams();
  const [form] = Form.useForm();
  const [freeBusyslots, setFreeSlots] = useState({}); // State for selected color
  const [selectedTimezone, setSelectedTimezone] = useState<string>(""); // State for timezone
  const [selectedColor, setSelectedColor] = useState<{
    id: string;
    color: string;
  }>({ id: "", color: "" }); // State for selected color
  const [setUrl, setOnlineMeetingUrl] = useState<string | null>("");
  const [copied, setCopied] = useState(false);

  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<AttendeeWithImage[]>([]); // Updated to hold Guest objects
  const [addedGuests, setAddedGuests] = useState<AttendeeWithImage[]>([]); // Updated to hold Guest objects
  const [activeTab, setActiveTab] = useState<string>("1"); // State to track active tab
  const [loading, setLoading] = useState<boolean>(false); // State to track active tab
  const [isColorPopoverVisible, setIsColorPopoverVisible] =
    useState<boolean>(false);
  const [meetingAccessSettingsVisible, setMeetingAccessSettingsVisible] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // Add loading state
  const [eventData, existingEventData] = useState<any>(); // Add loading state
  const [urlTrustLevel, setUrlTrustLevel] = useState<string>("open");
  const [isGeneratingMeetUrl, setGeneratingMeetUrl] = useState(false);
  // const [userAddedMeetCurrently, setUserAddedMeetCurrently] = useState(false);
  const [meetingSpaceNameMeet, setMeetingSpace] = useState("");
  const [meetingCode, setMeetingCode] = useState("");
  const [skypeOnlineMeeting, setIsSkypeOnlineMeeting] = useState(false);
  const [startTime, setStartTime] = useState(defaultStartTime1);
  const [startDate, setStartDate] = useState(dayjs(defaultStartTime.format('YYYY-MM-DD')));
  const [editStartDate, setEditStartDate] = useState(dayjs);
  const [endDate, setEndDate] = useState(dayjs(defaultEndTime.format('YYYY-MM-DD')));
  const [isVideoEnabled, setIsVideoEnabled] = useState(false); // State for video enabling
  const [isVideoUpdateEnabled, setIsUpdateVideoEnabled] = useState(false); // State for video enabling
  const [inviteLoading, setInviteLoading] = useState(false);
  const [totalMeeting, setTotalMeeting] = useState(totalMeetings?.total);
  const [availableMeetings, setAvailableMeetings] = useState(totalMeetings?.active);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const connectedClient: "outlook" | "google" = useSelector(
    (state: any) => state.calendar.connectedClient
  );
  const [attachments, setAttachments] = useState<Attachment[]>([]);
const [selectedCalendar, setSelectedCalendar]=useState("")  // Get the orgMembers object from the Redux store
  const loginUserData: loginUser = useSelector(
    (state: any) => state.opening.loginUser
  );
  const currentApplicant = useSelector(
    (state: RootState) => state.opening.currentApplicant
  );
  const currentJD = useSelector(
    (state: RootState) => state.opening.currentTrackerJd
  );

  const [showAll, setShowAll] = useState(false);
  let calendarList: calendarListState = useSelector(
    (state: RootState) => state.calendar.calendarList
  );
  const selectColors: any = useSelector(
    (state: RootState) => state.calendar.colors
  );

  const teamCalendar =
    Object.values(calendarList)?.find((item: any) => item.is_team_calendar) ||
    Object.values(calendarList).find((calendar: Calendars) => calendar.primary);

  const acceptedGuests = addedGuests.filter(
    (attendee) => attendee.responseStatus === "accepted"
  );
  // Toggle to show either the first 5 or all attendees
  const displayedAttendees = showAll ? addedGuests : addedGuests.slice(0, 5);
  // Function to determine the context based on URL parameters
  const getContext: any = () => {
    if (params.card_id && params.event_id) {
      return "tracker";
    } else if (params.board_id && params.event_id) {
      return "calendar";
    } else if (params.org_id && params.event_id) {
      return "event";
    }
    return null; // Default case if no context is found
  };

  function updateParticipantIds(participants: any[], members: any[]): any[] {
    if (!Array.isArray(participants) || !Array.isArray(members)) {
        console.error("Invalid input: participants and members should be arrays.");
        return [];
    }

    return participants.map((participant: any) => {
        if (typeof participant !== "object" || participant === null) {
            console.error("Invalid participant:", participant);
            return participant;
        }

        const matchingMember = members.find(
            (member: any) =>
                member && typeof member === "object" && member.email === participant.email
        );

        return {
            ...participant,
            id: matchingMember && typeof matchingMember.id !== "undefined"
                ? matchingMember.id
                : participant.id, // Update id if a valid match is found
        };
    });
}

 
  useEffect(() => {    
    if (params.event_id && params.calendar_id  && params.calendar_id!="meeting") {
      setLoading(true);
      getTrackerCardEventById(
        params.calendar_id,
        params.event_id,
        loginUserData.user.id
      )
        .then((data) => {
          setAttachments(data.attachments)
          setLoading(false);
          if (data) {
            if (data.online_meeting_url) {
              setOnlineMeetingUrl(data.online_meeting_url);
              // const res = await getConferneceDetails(online_meeting_id)
            }
            let startDateTime;
            let endDateTime;
            let dateTimeRange;
            if (data.all_day_event) {
              startDateTime = dayjs(data.start.date).startOf("day"); // Sets time to 00:00:00
              endDateTime = dayjs(data.end.date).endOf("day"); // Sets time to 00:00:00
              setSelectedTimezone(getUserTimezone().name);
              dateTimeRange = [startDateTime, endDateTime];
              setEditStartDate(startDateTime)
            } else {
              startDateTime = dayjs(data.start.date_time).tz(
                data.start.time_zone || getUserTimezone().name
              );
              endDateTime = dayjs(data.end.date_time).tz(
                data.end.time_zone || getUserTimezone().name
              );
              setSelectedTimezone(
                data.start.time_zone || getUserTimezone().name
              );
              setEditStartDate(startDateTime)
              dateTimeRange = [startDateTime, endDateTime];
            }
            // Set initial form values based on eventData
            form.setFieldsValue({
              summary: data.summary ?? "",
              location: data.location ?? "",
              description: data.description ?? "",
              dateTimeRange: dateTimeRange ?? "",
              startDate: startDateTime ?? "",
              startTime: startDateTime ? startDateTime.format("hh:mm A") : "",
              endDate: endDateTime ?? "",
              endTime: endDateTime ? endDateTime.format("hh:mm A") : "",
              visibility: data.visibility ?? "",
              reminderTime: data.reminders?.overrides[0]?.minutes ?? "",
              reminderType:data.reminders?.overrides[0]?.method ?? "",
              allDayEvent: data.all_day_event ?? false,
              selectedCalendar:
                Object.values(calendarList).find(
                  (calendar: Calendars) => calendar.id === params.calendar_id
                )?.display_name ?? "",
            });

            setSelectedColor(
              { id: data.color_id, color: data.event_color } || {
                id: "",
                color: "",
              }
            );
            const isRecordingEnabled = data.extended_properties?.private?.recording_enabled?.toLowerCase() === "true";
            setIsVideoEnabled(isRecordingEnabled)
            setIsUpdateVideoEnabled(isRecordingEnabled)
            setOnlineMeetingUrl(data.conference_data?.entry_points?.[0]?.uri);

            existingEventData(data);
            const updatedParticipants = updateParticipantIds(data.attendees, Object.values(orgMembers));
            setAddedGuests(
              updatedParticipants || [
                {
                  email: loginUserData.user.email,
                  id: loginUserData.user.id,
                  additional_guests: 0,
                  comment: "",
                  display_name: "",
                  optional: false,
                  organizer: true,
                  resource: false,
                  responseStatus: "",
                  self: true,
                  image: loginUserData.user.imageUrl,
                  // "organizer": true,
                },
              ]
            );
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      let jdAttachment: any = {
        id: currentJD.id,
        name: currentJD.title,
        objectKey: currentJD.objectKey,
        modelName: "Jd",
        presignedLink: currentJD.attachment_url,
        // Add other properties as needed
      };
      setAttachments([jdAttachment]);

      form.setFieldsValue({
        summary: defaultEventData.summary,
        location: defaultEventData.location,
        description: defaultEventData.description,
        dateTimeRange: defaultEventData.dateTimeRange,
        visibility: defaultEventData.visibility,
        reminderTime: defaultEventData.reminderTime,
        reminderType: defaultEventData.reminderType,
        selectedCalendar: teamCalendar?.display_name??"",
        allDayEvent: false,
      });
      setSelectedCalendar(teamCalendar?.id)
      // Add the logged-in user to the guest list if no event_id
      setAddedGuests([
        {
          email: loginUserData.user.email,
          id:loginUserData.user.id,
          additional_guests: 0,
          comment: "",
          displayName:`${loginUserData.user.firstName} ${loginUserData.user.lastName}`,
          optional: false,
          organizer: true,
          resource: false,
          responseStatus: "accepted",
          self: true,
          image: loginUserData.user.imageUrl,
        },
        {
          email: currentApplicant?.source?.email,
          is_candidate: true,
          id: currentApplicant?.source?.id,
          additional_guests: 0,
          comment: "",
          displayName: currentApplicant.name,
          optional: false,
          organizer: false, // Assuming this guest is not an organizer
          resource: false,
          responseStatus: "tentative",
          self: false,
          image: "", // Provide a default image or handle it appropriately
        },
      ]);
      const [firstId, firstColor]: any = Object.entries(selectColors.event)[0];

      setSelectedColor({
        id: firstId,
        color: firstColor.background,
      });
      setSelectedTimezone(getUserTimezone().name);
    }
  }, [calendarList]);

  useEffect(() => {
    const sendMeetCodeToApi = async (meetCode:string) => {
      try {
        const res = await getConferneceDetails(meetCode)
        if (res) {
          // setUserAddedMeetCurrently(true);
        setMeetingSpace(res.name);
        setMeetingCode(res.meetingCode);
        setOnlineMeetingUrl(res.meetingUri);
        setUrlTrustLevel(res.config.accessType.toLowerCase());
        } 
      } catch (error) {
        console.error('Network Error:', error);
      }
    };

    if(setUrl){
    // Extract the meet code from the URL
    const meetCode:string = setUrl.split('/').pop()||""; // Get the last part of the URL
    sendMeetCodeToApi(meetCode);
    }
  }, [setUrl]); 


  useEffect(() => {
    const sendMeetCodeToApi = async (meetCode:string) => {
      try {
        const res = await getConferneceDetails(meetCode)
        if (res) {
          // setUserAddedMeetCurrently(true);
        setMeetingSpace(res.name);
        setMeetingCode(res.meetingCode);
        setOnlineMeetingUrl(res.meetingUri);
        setUrlTrustLevel(res.config.accessType.toLowerCase());
        } 
      } catch (error) {
        console.error('Network Error:', error);
      }
    };

    if(setUrl){
    // Extract the meet code from the URL
    const meetCode:string = setUrl.split('/').pop()||""; // Get the last part of the URL
    sendMeetCodeToApi(meetCode);
    }
  }, [setUrl]); 

  const orgMembers: { [key: string]: Member } = useSelector(
    (state: any) => state.opening.members
  );
  const dispatch = useDispatch(); // Initialize dispatch

  const handleSearch = (value: string): AttendeeWithImage[] => {
    setSearchText(value);
    form.setFields([
      {
        name: "email",
        errors: [],
      },
    ]);
    if (value) {
      let searchResults: AttendeeWithImage[] = [];

      Object.values(orgMembers).forEach((member: Member) => {
        if (member && member.email) {
          // Ensure fields exist before applying search logic
          if (member.email.toLowerCase().includes(value.toLowerCase())) {
            // Create a new AttendeeWithImage object
            const attendee: AttendeeWithImage = {
              additional_guests: 0, // Default or fetch actual values
              comment: "", // Default or fetch actual values
              displayName: `${member.first_name || ""} ${member.last_name || ""}`.trim(), // Construct the display name or set an empty string
              email: member.email,
              id: member.id,
              optional: false, // Default or fetch actual values
              organizer: false, // Default or fetch actual values
              resource: false, // Default or fetch actual values
              responseStatus: "needsAction", // Default or fetch actual values
              self: false, // Default or fetch actual values
              image: member.image_url || "", // Add the image field
            };

            searchResults.push(attendee); // Push the new object
          }
        }
      });

      setSearchResults(searchResults);
      return searchResults;
    } else {
      setSearchResults([]);
      return [];
    }
  };

  // Update addGuest function to accept an AttendeeWithImage object
  const addGuest = (attendee: AttendeeWithImage) => {
    // Check if the attendee's email is already added
    if (
      !addedGuests.some(
        (existingGuest) => existingGuest.email === attendee.email
      )
    ) {
      // Add the AttendeeWithImage object to the addedGuests array
      setAddedGuests([...addedGuests, attendee]);
    }
    // Check if the "Find Time" tab is active
    if (activeTab === "2") {
      fetchFreeBusySlots([...addedGuests, attendee]); // Call fetchFreeBusySlots if on the "Find Time" tab
    }

    // Reset search text and results after adding
    setSearchText("");
    setSearchResults([]);
  };
  // Update removeGuest function to accept a Guest object
  const removeGuest = (attendee: AttendeeWithImage) => {
    // Check if the attendee's email is already added
    if (
      addedGuests.some(
        (existingGuest) => existingGuest.email === attendee.email
      )
    ) {
      // Add the AttendeeWithImage object to the addedGuests array
      setAddedGuests(
        addedGuests.filter(
          (existingGuest) => existingGuest.email !== attendee.email
        )
      ); // Filter by email property
    }
    // Check if the "Find Time" tab is active
    if (activeTab === "2") {
      fetchFreeBusySlots(
        addedGuests.filter(
          (existingGuest) => existingGuest.email !== attendee.email
        )
      ); // Call fetchFreeBusySlots if on the "Find Time" tab
    }

    // Reset search text and results after adding
    setSearchText("");
    setSearchResults([]);
  };
  // Create a mapping of context to action
  const actionMap: { [key: string]: (data: any) => void } = {
    tracker: (data) => {
      
      const transformedData = transformEvents(data); // Transform the data
      dispatch(editCardEvent({ ...transformedData, card_id: params.card_id }));
    }, calendar: (data) =>
      dispatch(
        editEventInBoardCalendar({
          calendarId: params.calendar_id,
          eventId: params.event_id,
          updatedEvent: Object.values(processEvents([data],params.calendar_id))[0],
        })
      ),
    event: (data) =>
      dispatch(
        editEventInGlobalCalendar({
          calendarId: params.calendar_id,
          eventId: params.event_id,
          updatedEvent: Object.values(processEvents([data],params.calendar_id))[0],
        })
      ),
  };

  

  function buildConferenceData({
    googleMeet,
    skypeOnlineMeeting,
  }: ConferenceDataOptions) {
    if (googleMeet) {
      return {
        conferenceSolution: {
          name: "Google Meet",
          key: {
            type: "hangoutsMeet",
          },
        },
        entryPoints: [
          {
            entryPointType: "video",
            uri: googleMeet,
            label: "Conference Link",
            meetingCode: meetingCode,
          },
        ],
      };
    }

    if (skypeOnlineMeeting) {
      return {
        createRequest: {
          conferenceSolutionKey: {
            type: "skypeforconsumer",
          },
        },
      };
    }

    return undefined;
  }
  const handleCreateEvent = (formData: any) => {
    const { summary, description, dateTimeRange, visibility ,reminderTime,reminderType} = formData;

    // Get the selected dates from the form
    const startDate = dayjs(dateTimeRange[0]);
    const endDate = dayjs(dateTimeRange[1]);

    const startInTimeZone = startDate
      .clone()
      .tz(selectedTimezone, true)
      .format();
    const endInTimeZone = endDate.clone().tz(selectedTimezone, true).format();

    // Check if the Google Meet link is being removed
    const conferenceData = buildConferenceData({
      googleMeet: connectedClient === "google" ? setUrl || undefined : undefined,
      skypeOnlineMeeting: connectedClient === "outlook" ? skypeOnlineMeeting : undefined,
    });

    // If the Google Meet link is removed, set conferenceData to null
    const finalConferenceData = setUrl ? conferenceData : null;

    const formattedAttachments = attachments
      .filter((file: any) => file.presignedLink) // Filter out files without a presignedLink
      .map((file: any) => ({
        fileUrl: file.presignedLink, // Ensure this exists
        mimeType: file.type || "application/octet-stream", // Default MIME type
        title: file.name || "Attachment",
        fileId:""
      }));

    const requestBody: any = {
      summary: summary,
      description: description,
      start: {
        dateTime: startInTimeZone,
        timeZone: selectedTimezone, // Adjust as needed
      },
      end: {
        dateTime: endInTimeZone,
        timeZone: selectedTimezone, // Adjust as needed
      },
      attendees: addedGuests,
      attachments: formattedAttachments, // Add attachments
      conferenceData: finalConferenceData , // Add if defined
      recording_enabled: isVideoEnabled,
      extendedProperties: {
        private: {
          card_id: currentApplicant.id,
          board_id: params.board_id,
          recording_enabled: `${isVideoEnabled}`
        },
      },
      reminders: {
        useDefault: false,
        overrides: [
          { method: reminderType, minutes: reminderTime },
        ],
      },
      colorId: selectedColor.id,
      visibility: visibility,
    };

    setIsSubmitting(true);
    if (params.event_id) {
      const changedFields: any = Object.keys(requestBody).reduce(
        (acc: any, key: string) => {
          if (
            JSON.stringify(requestBody[key]) !== JSON.stringify(eventData[key])
          ) {
            acc[key] = requestBody[key];
          }
          return acc;
        },
        {}
      );

      updateScheduledEvent(
        changedFields,
        loginUserData.user.id,
        params.event_id,
        params.calendar_id,
        isVideoEnabled
      )
        .then((response) => {
          if(response.extended_properties.recording_enabled!=isVideoUpdateEnabled){
            const activeMeetings = totalMeetings.active;
            if(response.extended_properties.recording_enabled === "false"){
              getActiveMeetings({
                active:activeMeetings-1,
                total:totalMeetings.total,
              })
            }else{
            getActiveMeetings({
              active:activeMeetings+1,
              total:totalMeetings.total,
            })
          }}
          handleCloseModal();
          actionMap[getContext()]?.(response);
          setIsSubmitting(false);
          form.resetFields();
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.error("Error scheduling event:", error);
        });
    } else {
      // Create new event
      const request1 = {
        ...requestBody,
        // colorId: selectedColor.id,
        extendedProperties: {
          private: {
            card_id: currentApplicant.id,
            board_id: params.board_id,
            recording_enabled: `${isVideoEnabled}`
          },
        },
      };
      scheduleEvent(request1, loginUserData.user.id, selectedCalendar,isVideoEnabled) // Pass the determined calendar ID
        .then((response) => {
          if (response) {
            if(response.extended_properties.recording_enabled=="true"){
              const activeMeetings = totalMeetings.active;
              getActiveMeetings({
                active:activeMeetings+1,
                total:totalMeetings.total,
              })
            }
            handleCloseModal();
            setIsSubmitting(false);
            throwSuccess(EVENT_CREATION_SUCCESS_MSG);
            form.resetFields();
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          form.resetFields();

          // onError(error); // Call the error callback with the error
          console.error("Error scheduling event:", error);
        });
    }
  };

  const fetchFreeBusySlots = (guests: AttendeeWithImage[]) => {
    const dateTimeRange = form.getFieldValue("dateTimeRange"); // Get the selected dateTimeRange from the form
    // const startTime = dateTimeRange[0].startOf("day").toISOString(); // Convert to ISO string with only date
    // const endTime = dateTimeRange[1].endOf("day").toISOString(); // Convert to ISO string with only date
    // Get the selected dates from the form
    const startDate = dateTimeRange[0];
    const endDate = dateTimeRange[1];
    const startTime = startDate.clone().tz(selectedTimezone, true).format();
    const endTime = endDate.clone().tz(selectedTimezone, true).format();
    const requestBody = {
      timeMin: startTime, // Use the selected start time
      timeMax: endTime, // Use the selected end time
      items: guests.map((guest) => ({ id: guest.email })), // Use the passed guests array for IDs
    };

    getfreeBusySlot(loginUserData.user.id, requestBody) // Call the API with the emails
      .then((slots) => {
        // Handle the slots data (you may want to set it in state to display)
        setFreeSlots(slots);
        // You can set the slots in a state variable to display them in the UI
      })
      .catch((error) => {
        console.error("Error fetching free/busy slots:", error);
      });
  };

  // Declare events and resources outside the conditional block
  let events = []; // Default value
  let resources: any = []; // Default value

  // Check if freeBusyslots is not empty before converting
  if (Object.keys(freeBusyslots).length > 0) {
    ({ events, resources } = convertFreeBusyToEvents(freeBusyslots)); // Destructure directly into the declared variables
  }

  // Function to validate email format
  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const validateEmail = async (email: string) => {
    if (!isValidEmail(email))
      form.setFields([
        {
          name: "email",
          errors: ["The input is not valid E-mail!"],
        },
      ]);
  };

  const handleInviteClick = () => {
    setInviteLoading(true); // Set loading to true
    inviteMember({
      invitee: [{ email: searchText }], // Invite this attendee
      user_type: UserType.INTERVIEWER, // Set the user type accordingly
      subscription_item_id: "", // Use the appropriate subscription ID
      user_source: "tacitbase",
    })
      .then((res: any) => {
        addOutgoingInvitation(res[0].user[0])
        addGuest({
          email: searchText,
          image: "", // Provide a default image or handle it appropriately
          additional_guests: 0, // Set default value for required properties
          comment: "",
          displayName: res[0].user[0].first_name,
          id: res[0].user_id, // Use a unique ID generation if needed
          optional: false,
          organizer: false,
          resource: false,
          responseStatus: "needsAction",
          self: false,
        });
      })
      .finally(() => {
        setInviteLoading(false); // Set loading to false
      });
  };

  const searchMenu = (
    <Menu>
      {/* Show 'Use this email' suggestion if no results */}

      {searchResults.length === 0 && searchText && isValidEmail(searchText) ? (
        <Menu.Item
          key="custom-email"
          onClick={handleInviteClick}
        >
      <div style={{ display: 'flex', alignItems: 'center' }} className="gap-2">
        {inviteLoading &&     <LoadingOutlined />        }
        Use this email: <strong>{searchText}</strong>
      </div>          <Alert
          message="Please ensure the email is correct before sending the invitation."
          type="warning"
          showIcon
          style={{ margin: '4px 0px',fontSize:'12px',padding:'6px' }}
        />
        </Menu.Item>
      ) : (
        searchResults.map((item: AttendeeWithImage, index: number) => (
          <Menu.Item key={index} onClick={() => addGuest(item)}>
            {item.email} {/* Display the email of the attendee */}
          </Menu.Item>
        ))
      )}
    </Menu>
  );

  // Function to copy the meeting link to the clipboard
  const handleCopy = (link: string) => {
    // Copy meeting link to clipboard
    navigator.clipboard
      .writeText(link)
      .then(() => {
        message.success("Meeting link copied!");
        setCopied(true);

        // Reset copied state after 2 seconds
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        message.error("Failed to copy meeting link");
      });
  };
  const history = useHistory(); // Initialize useHistory

  // Update closeModal to navigate to the desired route
  const handleCloseModal = () => {
    const context = getContext(); // Determine the current context
    // Check context before extracting IDs
    
    if (context && params.event_id) {
      // Navigate based on the context
      if (context === "tracker") {
        history.push(
          `/o/${params.org_id}/b/${params.board_id}/tracker/${params.card_id}`
        ); // Navigate to tracker route with IDs
      } else if (context === "calendar") {
        history.push(`/o/${params.org_id}/b/${params.board_id}/calendar`); // Navigate to board calendar route with dynamic board ID
      } else if (context === "event") {
        history.push(`/o/${params.org_id}/calendar`); // Navigate to global calendar route
      }
    } else {
      closeModal();
      // Handle case where context is not recognized
      console.warn("Context not recognized, cannot navigate.");
    }
  };
  // const shouldShowMessage = !conferenceData || !setUrl;

  const handleDateTimeChange = (field: string, value: any) => {
    const currentValues = form.getFieldValue("dateTimeRange") || [
      defaultStartTime,
      defaultEndTime,
    ];
    let newStartDate = dayjs(currentValues[0]);
    let newEndDate = dayjs(currentValues[1]);
  
    // Clear dependent fields when clearing the start date/time
    if (!value) {
      if (field === "startDate" || field === "startTime") {
        form.setFieldsValue({
          dateTimeRange: null,
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        });
      }
      return;
    }
  
    try {
      switch (field) {
        case "startDate":
          newStartDate = dayjs(value)
            .hour(currentValues[0].hour())
            .minute(currentValues[0].minute());
          newEndDate = newStartDate.add(
            teamCalendar?.default_event_duration || 30,
            "minutes"
          );
          setStartDate(dayjs(newStartDate.format('YYYY-MM-DD')));
            setEndDate(dayjs(newEndDate.format('YYYY-MM-DD')))
            setStartTime(currentValues[0].format("hh:mm A"));
          break;

          case "startTime":
            if (typeof value === "string") {
              setStartTime(value);
              const startTime = dayjs(value, "hh:mm A");
              if (startTime.isValid()) {
                newStartDate = newStartDate
                  .hour(startTime.hour())
                  .minute(startTime.minute());
          
                // Calculate the new end time based on the default duration
                const newEndTime = dayjs(value, "hh:mm A").add(
                  teamCalendar?.default_event_duration || 30,
                  "minutes"
                );
          
                // Update the time of newEndDate while keeping its current date
                newEndDate = newEndDate
                  .hour(newEndTime.hour())
                  .minute(newEndTime.minute());
          
                // Ensure end date is not changed
                setEndDate(dayjs(newEndDate.format("YYYY-MM-DD")));
              }
            }
            break;          

        case "endDate":
          newEndDate = dayjs(value)
            .hour(currentValues[1].hour())
            .minute(currentValues[1].minute());
            setEndDate(dayjs(newEndDate.format('YYYY-MM-DD')))
          break;
  
        case "endTime":
          if (typeof value === "string") {
            const endTime = dayjs(value, "hh:mm A");
            if (endTime.isValid()) {
              newEndDate = newEndDate
                .hour(endTime.hour())
                .minute(endTime.minute());
                  // Check if the new end time is before the start time
                  if (newEndDate.isBefore(newStartDate)) {
                    newEndDate = newEndDate.add(1, "day"); // Move to the next day
                }
                setEndDate(dayjs(newEndDate.format('YYYY-MM-DD')))
            }
          }
          break;
      }

      // Validate dates
      if (!newStartDate.isValid() || !newEndDate.isValid()) {
        return;
      }

      // if (newEndDate.isBefore(newStartDate)) {
      //   newEndDate = newStartDate.add(
      //     teamCalendar?.default_event_duration || 30,
      //     "minutes"
      //   );
      // }

      // Update form with validated values
      form.setFieldsValue({
        dateTimeRange: [newStartDate, newEndDate],
        startDate: newStartDate,
        startTime: newStartDate.format("hh:mm A"),
        endDate: newEndDate,
        endTime: newEndDate.format("hh:mm A"),
      });
    } catch (error) {
      console.error("Error processing date/time:", error);
    }
  };

  useEffect(() => {
    if (!params.event_id) {
      // Set default values for new events
      form.setFieldsValue({
        startDate: defaultStartTime,
        startTime: defaultStartTime.format("hh:mm A"),
        endDate: defaultEndTime,
        endTime: defaultEndTime.format("hh:mm A"),
        dateTimeRange: [defaultStartTime, defaultEndTime],
      });
    }
  }, []);

  const handleAddGoogleMeet = () => {
    setGeneratingMeetUrl(true);
    createGoogleMeetLink()
      .then((res) => {
        setGeneratingMeetUrl(false);
        // setUserAddedMeetCurrently(true);
        setMeetingSpace(res.name);
        setMeetingCode(res.meetingCode);
        setOnlineMeetingUrl(res.meetingUri);
        setUrlTrustLevel(res.config.accessType.toLowerCase());
      })
      .catch(() => {
        setGeneratingMeetUrl(false);
      });
  };
  const handleUrlTrustLevelUpdate = (value: string) => {
    let req = {
      config: {
        accessType: value,
      },
    };
    setUrlTrustLevel(value);
    updateUrlAccess(req, meetingSpaceNameMeet).then((res) => {
      setUrlTrustLevel(res.config.accessType.toLowerCase());
    });
  };
  // Descriptive options for trust levels
  const trustLevelOptions = [
    {
      value: "open",
      label: "Open",
      description: "No one has to ask to join. Anyone can dial in.",
    },
    {
      value: "trusted",
      label: "Trusted",
      description:
        "People can join without asking if they belong to your organization or are invited using their Google Account. Everyone else must ask to join. Anyone can dial in by phone.",
    },
    {
      value: "restricted",
      label: "Restricted",
      description:
        "Anyone who hasn't been invited must ask to join, including people who dial in.",
    },
  ];

  // Color selection popover content (similar to your existing implementation)
  const colorPopoverContent = (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        gap: "8px",
        width: "200px",
      }}
      onClick={(e) => e.stopPropagation()} // Add this to prevent popover from closing
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
    >
      {selectColors.event &&
        Object.entries(selectColors.event).map(([id, color]: any) => (
          <div
            key={id}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <button
              className="rounded"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedColor({
                  id: id,
                  color: color.background,
                });
                // setIsColorPopoverVisible(false);
              }}
              style={{
                backgroundColor: color.background,
                width: "20px",
                height: "20px",
                border: "1px solid rgba(0,0,0,0.1)",
                cursor: "pointer",
                position: "relative",
                transition: "transform 0.1s ease",
                transform: selectedColor.id === id ? "scale(1.1)" : "scale(1)",
              }}
            >
              {selectedColor.id === id && (
                <CheckOutlined
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "#fff",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                />
              )}
            </button>
          </div>
        ))}
    </div>
  );

  // Color addon with popover
  const colorAddon = (
    <Popover
      open={isColorPopoverVisible}
      onOpenChange={(visible) => setIsColorPopoverVisible(visible)}
      content={colorPopoverContent}
      title={<Text>Event color</Text>}
      trigger="click"
      placement="bottomLeft"
      overlayStyle={{
        padding: 2,
      }} // Add padding around the popover content
      arrow={false}
    >
      <div
        onKeyDown={() => {}}
        tabIndex={0}
        role="button"
        onClick={() => setIsColorPopoverVisible(!isColorPopoverVisible)}
        className="flex items-center space-x-2 justify-center"
      >
        <div
          className="w-4 h-4 rounded "
          style={{ background: selectedColor.color }}
        />
        <div>
          <DownOutlined
            style={{ color: "rgba(0, 0, 0, 0.25)", height: 12, fontSize: 12 }}
          />
        </div>
      </div>
    </Popover>
  );

  const handleAttachmentsChange = (newAttachments: Attachment[]) => {
    setAttachments(newAttachments); // Store the attachments
  };
  
  useEffect(() => {
    setTotalMeeting(totalMeetings?.total);
    setAvailableMeetings(totalMeetings?.active);
  }, [totalMeetings]);

  return (
    <>
    <TacitBaseModal
      ModalTriggerButtonName=""
      keyboard={false}
      maskClosable={false}
      // onCancel={closeModal}
      visibility={true}
      width={"100%"}
      closeIcon={null}
      styles={{
        body: {
          // overflowY: "auto",
          maxHeight: "calc(100vh - 239px)", // Adjust height as needed
          width: "951px",
          padding: "16px 22px",
          height:"calc(100vh - 239px)"
        },
      }}
      title={
        <>
          <Overlay onClose={handleCloseModal} scope={Scope.DialogSection}>
            <div
              className={`w-full flex justify-between items-center py-3 px-4   
               
              `}
              style={{ height: 52 }}
            >
              <div className="">
                {getContext() == null ? "Create event" : "Update event"}
              </div>
              <div className="">
                <Button
                  icon={<CloseIcon />}
                  type="text"
                  onClick={handleCloseModal}
                ></Button>{" "}
              </div>
            </div>
            <Divider className="m-0 p-0" />
          </Overlay>
        </>
      }
      footer={
        <div className="flex justify-end gap-2 p-4">
          <Button type="default" typeof="submit" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            loading={isSubmitting}
            type="primary"
            onClick={() => form.submit()}
          >
            {params.event_id ? "Update Event" : "Create Event"}
          </Button>
        </div>
      }
      content={
        <div
          className="flex w-full h-full "
          // style={{
          //   margin: 0,
          //   position: "relative",
          //   overflowY: "auto", // Changed from overflow: "hidden"
          //   width: "951px",
          //   maxWidth: "951px",
          //   height: "calc(100vh - 00px )",
          //   // maxHeight: "calc(100vh - 260px )",
          //   borderRadius: "4px",
          //   padding: "16px",
          // }}
        >
          {loading ? (
            <div className="flex w-full h-full items-center justify-center">
              <Spin></Spin>
            </div>
          ) : (
            <Form
              className="w-full h-full"
              form={form}
              onFinish={handleCreateEvent}
              layout="vertical"
            >
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <FormItem
                  name="summary"
                  label="Title"
                  rules={[{ required: true, message: "Please enter a title" }]}
                >
                  <Input
                    style={{ flex: "1 1 auto", width: "590px" }}

                    addonBefore={colorAddon}
                    placeholder="Enter event title"
                  />
                </FormItem>
              </div>

              <div
                className="hidden"
                style={{ flex: "1 1 auto", width: "300px" }}
              >
                <FormItem name="allDayEvent" label="Event Title">
                  <Checkbox />
                </FormItem>
              </div>
              <div className="flex w-full gap-2 justify-between">
                <div className="w-full flex gap-2 flex-wrap">
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label="Event Date and Time"
                    name="dateTimeRange"
                    rules={[
                      {
                        required: true,
                        message: "Please select the event date and time",
                      },
                    ]}
                  >
                    <div
                      style={{ display: "flex",gap:8 }}
                    >
                      <Form.Item name="startDate">
                        <DatePicker
                          value={form
                            .getFieldValue("dateTimeRange")?.[0]
                            .format("YYYY-MM-DD")}
                          format="YYYY-MM-DD"
                          placeholder="Start Date"
                          onChange={(date) =>
                            handleDateTimeChange("startDate", date)
                          }
                          disabledDate={(current) => current && current.isBefore(dayjs(), "day")} // Disable dates before today
                        />
                      </Form.Item>{" "}
                      <Form.Item name="startTime">
                        <CustomTimePicker
                          mode="start"
                          value={form
                            .getFieldValue("dateTimeRange")?.[0]
                            .format("hh:mm A")}
                          onChange={(time) =>
                            handleDateTimeChange("startTime", time)
                          }
                          startTime={startTime}
                          startDate={startDate}
                          endDate={endDate}
                          endTime="11:59 PM"
                        />
                      </Form.Item>
                      <div className="items-center">
                        <SwapRightOutlined style={{ width: "12px" }} />
                      </div>
                      <Form.Item name="endTime">
                        <CustomTimePicker
                          mode="end"
                          value={form
                            .getFieldValue("dateTimeRange")?.[1]
                            .format("hh:mm A")}
                          onChange={(time) =>
                            handleDateTimeChange("endTime", time)
                          }
                          startTime={startTime}
                          endTime="11:59 PM"
                          endDate={endDate}
                          startDate={startDate}
                        />
                      </Form.Item>
                      <Form.Item name="endDate">
                        <DatePicker
                          value={form
                            .getFieldValue("dateTimeRange")?.[1]
                            .format("YYYY-MM-DD")}
                          format="YYYY-MM-DD"
                          placeholder="End Date"
                          onChange={(date) =>
                            handleDateTimeChange("endDate", date)
                          }
                          disabledDate={(current) => current && startDate && current.isBefore(dayjs(startDate), "day")} // Disable dates before today
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                  <FormItem label="Timezone" style={{ marginBottom: 0 }}>
                    <TimezoneSelect
                      placeholder="Enter timezone"
                      className="cursor-pointer"
                      styles={timezoneSelectCustomStyle("300px")}
                      value={selectedTimezone}
                      onChange={(e) => setSelectedTimezone(e.value)}
                    />
                  </FormItem>
                </div>
              </div>
              <div
                className="flex"
             
              >
                <div className="w-8/12 h-full overflow-y-scroll create-calender-tab">
                  {/* Tabs for Event Details and Find Time */}
                  <Tabs
                    className="h-full overflow-y-scroll"
                    defaultActiveKey="1"
                    activeKey={activeTab}
                    onChange={(key) => {
                      setActiveTab(key);
                      if (key === "2") {
                        // Check if the "Find Time" tab is active
                        fetchFreeBusySlots(addedGuests); // Call fetchFreeBusySlots with current guests
                      }
                    }}
                  >
                    <TabPane tab="Event Details" key="1" className="h-full ">
                      <div
                        className=" overflow-y-scroll"
                        style={{
                          height: "calc(-481px + 100vh)",
                          maxHeight: "calc(-481px + 100vh)",
                          // width: "955px",
                        }}
                      >
                        {connectedClient == "outlook" && !setUrl && (
                          <>
                            <Form.Item
                              label="Add conference"
                              className=" flex items-center "
                            >
                              <Text className="flex items-center justify-center">
                                <Checkbox
                                  value={skypeOnlineMeeting}
                                  onChange={(e) =>
                                    setIsSkypeOnlineMeeting(e.target.checked)
                                  }
                                />
                                <Skype />
                                Skype meeting
                              </Text>
                             </Form.Item> 
                          </>
                        )}
                        {connectedClient == "google" && !setUrl && (
                          <>
                            <FormItem className="font-semibold" label="Add conference">
                              {" "}
                              <Button
                                icon={
                                  <span className=" h-5 w-5 flex items-center justify-center">
                                    <MeetSvg />
                                  </span>
                                }
                                iconPosition="start"
                                onClick={handleAddGoogleMeet}
                                loading={isGeneratingMeetUrl}
                              >
                                Add google meet video conferencing
                              </Button>
                            </FormItem>
                          </>
                        )}

                        {setUrl && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                gap: "12px",
                                flexWrap: "wrap",
                              }}
                            >
                              <div>
                                <FormItem
                                  style={{ width: "270px" }}
                                  label={"Add conference"}
                                  className="w-full flex"
                                >
                                  <Space
                                    style={{ width: "270px" }}
                                    align="center"
                                    size="middle"
                                    className="w-full break-words flex "
                                  >
                                    <Space.Compact
                                      direction="vertical"
                                      style={{ width: "270px" }}
                                    >
                                      <Space.Compact block>
                                        <Button
                                          style={{ width: "250px" }}
                                          icon={
                                            connectedClient == "google" ? (
                                              <span className=" h-5 w-5 flex items-center justify-center">
                                                <MeetSvg />
                                              </span>
                                            ) : connectedClient == "outlook" ? (
                                              <span className="-ml-0.5 h-6 w-6 flex items-center justify-center">
                                                <Skype />{" "}
                                              </span>
                                            ) : null
                                          }
                                          onClick={() => {
                                            if (setUrl) {
                                              window.open(setUrl, "_blank");
                                            }
                                          }}
                                          className="left-align-button"
                                        >
                                          {connectedClient == "google"
                                            ? "Join with google meet"
                                            : connectedClient == "outlook"
                                            ? "Join skype meeting"
                                            : "join"}
                                        </Button>
                                        <Tooltip title="Copy">
                                          <Button
                                            icon={<CopyOutlined />}
                                            onClick={() => handleCopy(setUrl)}
                                            type={
                                              copied ? "primary" : "default"
                                            }
                                            className="flex items-center justify-center"
                                          ></Button>
                                        </Tooltip>
                                        <Tooltip title="Remove">
                                          <Button
                                            type="default"
                                            icon={<DeleteOutlined />}
                                            onClick={() =>
                                              setOnlineMeetingUrl("")
                                            }
                                          />
                                        </Tooltip>
                                      </Space.Compact>

                                      <Text
                                        style={{ fontSize: 12 }}
                                        type="secondary"
                                      >
                                        {setUrl}
                                      </Text>
                                    </Space.Compact>
                                  </Space>
                                </FormItem>
                              </div>
                              <div>
                                {" "}
                                <FormItem
                                  label={"Access type"}
                                  className="flex"
                                  style={{ width: "140px" }} 
                                >
                                  {connectedClient === "google" &&
                                     (
                                      <Select
                                        style={{ width: "140px" }} // Increase width if needed
                                        value={urlTrustLevel}
                                        onChange={handleUrlTrustLevelUpdate}
                                        optionLabelProp="label"
                                        dropdownRender={(menu) => (
                                          <div>{menu}</div>
                                        )}
                                        variant="filled"
                                      >
                                        {trustLevelOptions.map((option) => (
                                          <Select.Option
                                            key={option.value}
                                            value={option.value}
                                            label={option.label} // Used for the display label
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <span
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {capitalizeFirstLetter(
                                                  option.label
                                                )}
                                              </span>
                                              <span
                                                style={{
                                                  color: "#888",
                                                  whiteSpace: "normal",
                                                }}
                                              >
                                                {option.description}
                                              </span>
                                            </div>
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    )}
                                </FormItem>                              </div>
                                <div>
                                <FormItem
                  name="recording"
                  label={
                    <span>
                      {getContext()==null?
                      (availableMeetings >= totalMeeting && <Button size="small" className="mr-2" type="text" icon={<LockOutlined/>} onClick={()=>{setIsModalVisible(true)}}/>)
                      :(availableMeetings >totalMeeting && <Button size="small" className="mr-2" type="text" icon={<LockOutlined/>} onClick={()=>{setIsModalVisible(true)}}/>)}
                      Recording{' '}
                      <Tooltip title="Video recording requires a connected conference tool and a valid meeting URL. Please ensure both are set up.">
                        <InfoCircleOutlined style={{ marginLeft: 8}} />
                      </Tooltip>
                    </span>
                  }                >
                   <Switch checked={isVideoEnabled} onChange={(value) => setIsVideoEnabled(value)} 
                   disabled={getContext()==null?(!setUrl || availableMeetings >= totalMeeting):((!setUrl || availableMeetings > totalMeeting) || editStartDate.isBefore(now))} 
                   title={!setUrl?"You haven’t scheduled an interview. Please schedule an interview before starting the recording.":""}/>
                </FormItem>

                                </div>
                            </div>
                            {connectedClient === "google" &&
                              meetingAccessSettingsVisible && (
                                <Modal
                                  title="Meeting Access Type"
                                  visible={meetingAccessSettingsVisible}
                                  onCancel={() =>
                                    setMeetingAccessSettingsVisible(false)
                                  }
                                  footer={null}
                                >
                                  <Select
                                    style={{ width: "100%" }}
                                    value={urlTrustLevel}
                                    onChange={handleUrlTrustLevelUpdate}
                                    optionLabelProp="label"
                                    variant="filled"
                                  >
                                    {trustLevelOptions.map((option) => (
                                      <Select.Option
                                        key={option.value}
                                        value={option.value}
                                        label={option.label}
                                        title={option.description}
                                      >
                                        <div>
                                          {capitalizeFirstLetter(option.label)}
                                        </div>
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Modal>
                              )}
                          </>
                        )}
                        <div></div>

                        {/* Display the Google Meet link */}

                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            flexWrap: "wrap",
                            // width: "300px"
                          }}
                        >
                          {/* Location Input */}
                          <div>
                            {/* <FormItem label="Location" name={"location"}>
                            <Input
                              variant="filled"
                              placeholder="Enter location"
                            />
                          </FormItem> */}
                            <FormItem
                              style={{ width: "130px" }} // Fixed width for the Select
                              label="Reminder Time"
                              name="reminderTime"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a reminder time",
                                },
                              ]}
                            >
                              <Select
                                variant="filled"
                                style={{ width: "130px" }} // Fixed width for the Select
                              >
                                {[5, 10, 15, 20, 25, 30, 35].map((minutes) => (
                                  <Select.Option key={minutes} value={minutes}>
                                    {minutes} minutes before
                                  </Select.Option>
                                ))}
                              </Select>
                            </FormItem>
                            </div><div>
                            <FormItem
                              style={{ width: "130px" }} // Fixed width for the Select
                              label="Notification Type"
                              name="reminderType"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a reminder time",
                                },
                              ]}
                            >
                              <Select
                                variant="filled"
                                style={{ width: "130px" }} // Fixed width for the Select
                              >
                                {["email","popup"].map((minutes:string) => (
                                  <Select.Option key={minutes} value={minutes}>
                                    {capitalizeFirstLetter(minutes)}
                                  </Select.Option>
                                ))}
                              </Select>
                            </FormItem>
                          </div>
                          <div>
                            <FormItem
                              label={
                                <span>
                                  Select Calendar{" "}
                                  {teamCalendar &&
                                    !teamCalendar.is_team_calendar && (
                                      <Tooltip title="A team calendar hasnt been created yet. The primary calendar will be used by default until a team calendar is set up.">
                                        <WarningOutlined
                                          style={{
                                            marginLeft: 4,
                                            color: "#faad14",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                </span>
                              }
                              name={"selectedCalendar"}
                            >
                              <Select
                                variant="filled"
                                style={{ width: "280px" }} // Fixed width for the Select
                                value={
                                  // Get the current selected calendar's display name
                                  calendarList[
                                    form.getFieldValue("selectedCalendar")
                                  ]?.display_name ||
                                  (teamCalendar?.is_team_calendar &&
                                    teamCalendar.display_name) ||
                                  ""
                                }
                                onChange={(value) => {
                                  setSelectedCalendar(value);
                                  form.setFieldsValue({
                                    selectedCalendar: value,
                                  });
                                }}
                                dropdownRender={(menu) => <div>{menu}</div>}
                              >
                                {teamCalendar && teamCalendar.is_team_calendar && (
                                  <Select.Option
                                    key={teamCalendar.id}
                                    value={teamCalendar.id}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "200px", // Set a max width for truncation
                                        }}
                                      >
                                        {teamCalendar.display_name}
                                      </span>
                                      <Tag>Team Calendar</Tag>
                                    </div>
                                  </Select.Option>
                                )}
                                {Object.values(calendarList).map(
                                  (calendar) =>
                                    calendar &&
                                    !calendar.is_team_calendar && (
                                      <Select.Option
                                        key={calendar.id}
                                        value={calendar.id}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                          }}
                                        >
                                          <span
                                            style={{
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              maxWidth: "200px", // Set a max width for truncation
                                            }}
                                          >
                                            {calendar.display_name}
                                          </span>
                                        </div>
                                      </Select.Option>
                                    )
                                )}
                              </Select>
                            </FormItem>
                          </div>
                        </div>
                        {/* <FormItem label="Attachments"> */}
                        <CardEventAttachments
                        eventData={eventData}
                        initAttachment={attachments}
                          onAttachmentsChange={handleAttachmentsChange}
                        />

                        {/* </FormItem> */}
                        {/* Description Field with Rich Text Editor */}
                        <FormItem label="Description" name={"description"}>
                          <span className="calendar-desc">
                            <Editor
                              value={form.getFieldValue("description")}
                              onChange={(value) => {
                                form.setFieldValue("description", value);
                              }}
                              placeholder=""
                            />
                          </span>
                        </FormItem>
                      </div>
                    </TabPane>
                    <TabPane tab="Find Time" key="2" className="h-full ">
                      <div
                        className="h-full overflow-y-scroll"
                        style={{
                          height: "calc(-450px + 100vh)",
                          maxHeight: "calc(-450px + 100vh)",
                          // width: "955px",
                        }}
                      >
                        {resources.length > 0 ? (
                          <ResourceCalendar
                            resources={resources}
                            events={events}
                          />
                        ) : (
                          <Spin>
                            <ResourceCalendar
                              resources={resources}
                              events={events}
                            />{" "}
                          </Spin>
                        )}
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
                <div className="min-w-80 w-4/12">
                  <Space direction="vertical" className="flex w-full">
                    <Tabs className="mb-0 pb-0">
                      <TabPane tab="Add Guests:" key="3">
                        <Dropdown overlay={searchMenu} visible={!!searchText}>
                          <Input
                            variant="filled"
                            placeholder="Enter guest email"
                            value={searchText}
                            onChange={(e) => handleSearch(e.target.value)}
                            allowClear
                            onPressEnter={() => validateEmail(searchText)}
                          />
                        </Dropdown>
                      </TabPane>
                    </Tabs>
                    <div className="flex flex-col overflow-y-scroll pr-1" style={{maxHeight:"calc(100vh - 519px)"}}>
                      <Space.Compact direction="vertical">
                        <Text>{addedGuests.length} guests</Text>
                        <Text style={{ fontSize: 12 }} type="secondary">
                          {acceptedGuests.length} accepted
                        </Text>
                      </Space.Compact>
                      {/* Show the list of added guests */}
                      {displayedAttendees.length > 0 && (
                        <List
                          dataSource={addedGuests}
                          renderItem={(guest) => {
                            return (
                              <List.Item style={{ padding: "10px 0px" }}>
                                <GuestItem guest={guest}
                                 setAddedGuests={setAddedGuests} 
                                removeGuest={removeGuest}/>
                              </List.Item>
                            );
                          }}
                        />
                      )}
                      {addGuest.length > 5 && (
                        <button
                          className="show-more"
                          onClick={() => setShowAll(!showAll)}
                        >
                          {showAll
                            ? "Show Less"
                            : `Show More (${addGuest.length - 5} more)`}
                        </button>
                      )}
                    </div>{" "}
                  </Space>
                </div>
              </div>
            </Form>
          )}
        </div>
      }
      style={{
        overflowY: "hidden",
        width: " 100vw",
        height: "100vh",
        position: "fixed",
        top: "50px",
        left: "10px",
        bottom: "10px",
        right: "0px",
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        padding: "0px",
        borderRadius: "4px",
      }}
      className="review-modal-content"
    ></TacitBaseModal>
    <UpgradePlanModal isModalVisible={isModalVisible} handleCancel={()=>{setIsModalVisible(false)}} />
    {/* <VideoSummeryModal /> */}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  totalMeetings:state.opening.totalMeetings
});

const mapPropsToState = (dispatch: Dispatch) => ({
  getActiveMeetings:(data: any)=> dispatch(getActiveMeetings(data)),
  addOutgoingInvitation: (invitation: any) =>
    dispatch(addOrgMember(invitation)),
});
export default connect(mapStateToProps,mapPropsToState)(EventFormModal);
